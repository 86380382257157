import React, { useState, useEffect } from "react";
import { Dialog } from "@material-ui/core";
import "./Dashboard.css";
import DashboardTabs from "./DashboardTabs";
import DashboardTabMobile from "./DashboardTabMobile";
import  grey from "@material-ui/core/colors/grey";
import CloseIcon from "@material-ui/icons/Close";

const Dashboard = (props) => {
  const { openPopup, setOpenPopup } = props;
  const [scroll, setScroll] = useState("paper");
  const { session, setSession } = props;

  const [mobileSize, setmobileSize] = useState(window.matchMedia("(max-width: 820px)").matches)

    useEffect(() => {
      window.matchMedia("(max-width: 820px)").addEventListener('change', e => setmobileSize( e.matches ));
      return () => window.matchMedia("(max-width: 820px)").removeEventListener('change', e => setmobileSize( e.matches ));
    }, []);

  const handleClose = () => {
    setOpenPopup(false);
  };
  
  return (
    <Dialog
      open={openPopup}
      onClose={handleClose}
      scroll={scroll}
      fullScreen = {mobileSize}
      fullWidth
      maxWidth="xl"
      PaperProps={{
        style: {
          backgroundColor: "#f1f1f1",
          boxShadow: 'none',
        },
      }}
    >
      {/* {console.log(mobileSize)} */}
      {mobileSize ? 
        (<div>
          <CloseIcon
            id="close-summary"
            fontSize="medium"
            style={{ color: grey[400] }}
            onClick={() => handleClose()}
          />
          <DashboardTabMobile data={session}/>
        </div>) 
        : <DashboardTabs data={session}/>}
    </Dialog>
  );
};

export default Dashboard;