import React from "react";
import "./CardComponent.css";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";

// Function to determine the color of displayed values
  const choseColor = (compare) => {
    if (compare === "OUTLIER_DETECTED" || compare === "OUTSIDE_LOW" || compare === "OUTSIDE_HIGH") {
      return("#E94646");
    } else if (
      compare === "UPPER_QRT" ||
      compare === "LOWER_QRT"
    ) {
      return ("#FFD07B")
    } else if (compare === "IN_RANGE") {
      return "#2dc22d"
    } else if (compare === "NO_BASELINE_DATA") {
      return "#DDA0DD";
    } else {
      return "#000000"
    }
  }

export default function SessionboardCard(props) {
  const { session_data } = props;
  let predvalue;
  {(session_data.prediction && session_data.prediction !== 'NO_BASELINE_DATA' ) ? (predvalue = (session_data.prediction).match(/\d+\.\d+/)) : (predvalue = "0")}
  //Compare manually set.
  //When comparing value is added please change the object's compare value
  const cards = [
    {
      Average_HR: {
        value: session_data.avg_hr,
        compare: choseColor(session_data.hr_compare),
      },
    },
    {
      Prediction_Min: {
        value: predvalue,
        compare: choseColor(""),
      },
    },
    {
      Average_RMSSD: {
        value: session_data.avg_rmssd,
        compare: choseColor(session_data.rmssd_compare),
      },
    },
    {
      Distance: {
        value: session_data.distance,
        compare: choseColor(""),
      },
    },
  ]
  
  

  return (
    <div className="card-container">
      <div className="card__details">
        <CalendarTodayIcon className="session-calendar-icon" style={{ display: "flex", float: "left" }} />
        <div className="session-date">{session_data.sessionDate}</div>
        <div wrap = "no wrap" className="card-fatiguescore-box">
          <div className="fatiguescore-text">Fatigue Score</div>
          <span className="fatiguescore-value">{session_data.fatigue_score}</span>
        </div>
        <div className="card-info-boxes">
          {cards.map((card, index) => {
            // Get key of the object and reform the string
            let category = Object.keys(card)[0];
            let name = category.replace(/_/g, " ");
            // Each small tile
            return (
            <div key={category} className="card-info-box">
            <div className="card-info-title">{name}</div>
            <div className={"session-value"}>
              <div style={{ color: card[category].compare }}>{card[category].value}</div>
            </div>
          </div>
            )
          })}
        </div>
        
      </div>
    </div>
  );
}
