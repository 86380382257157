import React, { useState, useContext } from "react";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import { Auth } from "aws-amplify";
import { userSignedIn } from '../../actions/authActions';
import { useDispatch } from "react-redux";

export default function NewPasswordForm(props) {
  const { openPopup, setOpenPopup } = props;
  const { username, setusername } = props;
  const { password, setPassword } = props;
  const [confirmPassword, setConfirmPassword] = useState();
  const [newPassword, setNewPassword] = useState("");
  const dispatch = useDispatch();


  const onSubmit = async () => {
    const user = Auth.signIn(username, password)
      .then((user) => {
        Auth.completeNewPassword(user, newPassword)
          .then((user) => {
            console.log(user);
            dispatch(userSignedIn());
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Dialog open={openPopup}>
      <DialogTitle>
        <div>Change password</div>
      </DialogTitle>
      <DialogContent>
        <div>
          <label>New password</label>
          <input
            type="password"
            value={newPassword}
            onChange={(event) => setNewPassword(event.target.value)}
          />
          <br></br>
          <lable>Confirm password</lable>
          <input
            type="password"
            value={confirmPassword}
            onChange={(event) => setConfirmPassword(event.target.value)}
          />
          <br></br>
          <button
            type="submit"
            onClick={() => {
              if (newPassword === confirmPassword) {
                onSubmit();
              } else {
                alert("You have to match confirm password");
              }
            }}
          >
            Change password
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
