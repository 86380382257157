import React from 'react';
import { Breadcrumbs as MUIBreadcrumbs, Link, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';


const BreadCrumbs = (props) => {

  const { history, location: { pathname } } = props;
  const pathnames = pathname.split("/").filter(x => x);
 

  return (
    <div className = "breadcrumb-bar">
    <MUIBreadcrumbs aria-label="breadcrumb" >
      <Link onClick={() => history.push("/")}>Home</Link>
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`
        const isLast = index === pathnames.length - 1;
        return isLast?
       ( <div key={name}><Typography> {name} </Typography></div>
       ) :(
        //  <div key={name}><Typography> {name} </Typography></div>
        <div key={name}><Link onClick={() => history.push(routeTo)}><div className= 'breadcrumb-pathname'>{name}</div></Link></div>
      )})}
    </MUIBreadcrumbs>
    </div>
  ); 
}

export default withRouter(BreadCrumbs);




