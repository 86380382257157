import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Box from "@material-ui/core/Box";
import TimelineIcon from "@material-ui/icons/Timeline";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { makeStyles } from "@material-ui/core";
import DayLineChart from "../Dashboard/DayLineChart";
import SummaryScreen from "../../screens/SummaryScreen";
import "./DashboardTabs.css";
import EcgLineChart from "../Dashboard/EcgLineChart";
import PhotoPresentor from "../Dashboard/PhotoPresentor";
import MapTracker from "../Dashboard/MapTracker";
import {Legend2} from "./Legend";

export default function DashboardTabs(props) {
  const [value, setValue] = React.useState("1");
  const useStyles = makeStyles((theme) => ({
    appContainer: {
      display: "flex",
      flexDirection: "column",
      height: "86vh",
    },
    container: {
      "& .MuiBox-root": {
        padding: "0px",
      },
      alignItems: "center",
      justifyContent: "center",
    },

    graphCard: {
      background: "white",
      minWidth: "70%",
      maxWidth: "100%",
      borderRadius: "15px",
      position: "relative",
      padding: "5px 5px 0px 5px",
    },
  }));
  const classes = useStyles();


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box className={classes.appContainer}>
      <TabContext value={value}>
        <AppBar position="static" color="default" style={{ width: "100%" }}>
          <TabList onChange={handleChange} centered>
            <Tab label="Summary" value="1" icon={<DashboardIcon />} />
            <Tab label="Details" value="2" icon={<TimelineIcon />} />
          </TabList>
        </AppBar>

        <Box>
          <TabPanel value="1" classes={{ root: classes.container }}>
            <Box>
              {<SummaryScreen data={props.data}/>}
            </Box>
          </TabPanel>
          <TabPanel value="2" classes={{ root: classes.container }}>
            <Box>
              
              <div className="wrapper">
                <div className={classes.graphCard}>
                  <Legend2/>
                  <DayLineChart />
                </div>
                <div className="mapCard">
                  <MapTracker />
                </div>
                <div className={classes.graphCard}>
                  <EcgLineChart />
                </div>
                <div className="photoCard">
                  <PhotoPresentor />
                </div>
              </div>
              
            </Box>
          </TabPanel>
        </Box>
      </TabContext>
    </Box>
  );
}
