import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchImage } from "../../actions/authActions";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./PhotoPresentor.css";
import { FETCH_TIME } from "../../constants/authConstants";

const PhotoPresentor = () => {
  const fcamData = useSelector((state) => state.authInfo.fcamDB);
  const imgByte = useSelector((state) => state.authInfo.imageDB);
  const dispatch = useDispatch();

  useEffect(() => {
    const imgFileList = fcamData.map(
      (d) =>
        d.payload.front_facing_camera
          .replace(".jpg", "_bounded.jpg")
          .split("fatiguem8-data-store/")[1]
    );
    dispatch(fetchImage(imgFileList));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fcamData]);

  function encode(byte) {
    let buf = Buffer.from(byte);
    let base64 = buf.toString("base64");
    return base64;
  }

  function handleSelected(n, i) {
    const time = new Date(document.getElementById(`legend_${n}`).innerHTML);
    dispatch({ type: FETCH_TIME, payload: time });
  }

  return (
    <Carousel infiniteLoop={true} selectedItem={0} onChange={handleSelected} onClickItem={handleSelected}>
      {imgByte.length > 0 ? (
        imgByte.map((d, i) => {
          const time = fcamData[i];
          return (
            <div key={i}>
              <img
                alt="FCAM"
                className={i > 0 ? "fcam_img" : "fcam_img selected"}
                id={`fcam_img_${i}`}
                src={`data:image/jpeg;base64,${encode(imgByte[i])}`}
              />
              {time !== undefined ? (
                <p className="legend" id={`legend_${i}`}>
                  {time.event_time.toString()}
                </p>
              ) : (
                <p></p>
              )}
            </div>
          );
        })
      ) : (
        <div>
          <p>No Images</p>
        </div>
      )}
    </Carousel>
  );
};

export default PhotoPresentor;
