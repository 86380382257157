import React from 'react';
import SummaryCard from '../components/Card/SummaryCard';
function SummaryScreen(props) {
    return (
        <div>
        <SummaryCard data={props.data}/>
       
        </div>
    );
}

export default SummaryScreen;