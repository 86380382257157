import { USER_SIGNED_IN, FETCH_ORG, FETCH_DRIVER, FETCH_SESSION, FETCH_FAIL, SET_ORG_TOKEN, SET_DRIVER_TOKEN, USER_LOGOUT, LOGIN_SUCCESS, SET_USER_GROUP, FETCH_REQUEST, FETCH_SUCCESS, FETCH_DATE, FETCH_FCAM,FETCH_ECG,FETCH_MAP,FETCH_TIME, FETCH_IMAGES,FETCH_ONE_ECG, FETCH_PROFILE, CLEAR_DRIVER, CLEAR_SESSION } from '../constants/authConstants';

const initialState = {
    orgDB: [],
    driverDB: [],
    sessionDB: [],
    dateDB: [],
    imageDB: [],
    timeDB: new Date(),
    ecgDB: [],
    fcamDB: [],
    mapDB: [],
    oneEcgDB: [],
    signedIn: false,
    loading: false,
    orgToken: "",
    driverToken: "",
    profileToken: "",
};

export const authReducers = (state = initialState, action) => {
    switch (action.type) {
        case USER_SIGNED_IN:
            return {
                ...state,
                signedIn: action.payload
            }
        case SET_ORG_TOKEN:
            return {
                ...state,
                orgToken: action.payload
            }
        case SET_DRIVER_TOKEN:
            return {
                ...state,
                driverToken: action.payload
            }
        case FETCH_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case FETCH_FAIL:
            return {
                ...state,
                loading: false,
            }
        case FETCH_ORG:
            return {
                ...state,
                orgDB: action.payload
            }
        case FETCH_DRIVER:
            return {
                ...state,
                driverDB: action.payload
            }
        case FETCH_SESSION:
            return {
                ...state,
                sessionDB: action.payload
            }
        case FETCH_DATE:
            return {
                ...state,
                dateDB: action.payload
            }
        case FETCH_MAP:
            return{
                ...state,
                mapDB: action.payload
            }
        case FETCH_ECG:
            return{
                ...state,
                ecgDB: action.payload
            }
        case FETCH_FCAM:
            return{
                ...state,
                fcamDB: action.payload
            }
        case FETCH_TIME:
            return {
                ...state,
                timeDB: action.payload
            }
        case FETCH_IMAGES:
            return {
                ...state,
                imageDB: action.payload
            }
        case FETCH_ONE_ECG:
            return {
                ...state,
                oneEcgDB: action.payload
            }
        case FETCH_PROFILE:
            return {
                ...state,
                profileToken: action.payload
            }
        case CLEAR_DRIVER:
            return {
                ...state,
                driverDB: action.payload
            }
        case CLEAR_SESSION:
            return {
                ...state,
                sessionDB: action.payload
            }
        default:
            return state;
    }
}