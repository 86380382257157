import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Hub } from 'aws-amplify';
import { Redirect  } from 'react-router-dom';
import { USER_SIGNED_IN, USER_LOGOUT } from '../../constants/authConstants';
import AppHeader from '../AppHeader';
import { userSignedIn } from '../../actions/authActions';

import '../../App.css';

function Nav() {

    const userGroups = useSelector((state) => state.sessionInfo.usergroup);
    const signedIn = useSelector((state) => state.authInfo.signedIn);
    const dispatch = useDispatch();
    const orgToken = useSelector((state) => state.authInfo.orgToken);
    const driverToken = useSelector((state) => state.authInfo.driverToken);
    
    useEffect(() => {

      console.log(typeof(orgToken));

        Hub.listen("auth", ({ payload: { event, data } }) => {
          // eslint-disable-next-line default-case
          switch (event) {
            case "signIn":
              dispatch(userSignedIn());
              break;
            case "signOut":
              dispatch({type: USER_SIGNED_IN, payload: false});
              dispatch({type: USER_LOGOUT});
              break;
            case "signIn_failure":
              console.log("Sign in failure", data);
              break;
          }
        });

      }, [dispatch, orgToken]);


    return (
      <div>
          {signedIn ? (
            <>
              <AppHeader />
              {userGroups &&
              userGroups.filter((f) => f.indexOf("org-admin") > -1).length >
                0 ? (
                  <Redirect to={ "/" + orgToken + "/selectdriver" } />
              ) : userGroups && 
                  userGroups.filter((f) => f.indexOf("KJR") > -1 ).length >
                  0 ? (
                    <Redirect to={"/selectorg"}/>
                  ): <Redirect to={"/" + orgToken + "/" + driverToken + "/selectsession"}/>}
            </>
          ) : (
            <>
            <AppHeader/>
                <Redirect to="/login"/>
            </>
          )}
       </div>
    );
    
}

export default Nav;