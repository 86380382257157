import React from 'react';
import Login from '../components/Login/Login';

function LoginScreen() {

    return(
        <Login/>
    );
}


export default LoginScreen;