import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Amplify from 'aws-amplify';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';

Amplify.configure({
  Auth: {
    region: 'ap-southeast-2',
    identityPoolId: 'ap-southeast-2:b98a8d0f-88ce-4c41-9688-c8546ff63406',
    identityPoolRegion: 'ap-southeast-2',
    userPoolId: 'ap-southeast-2_IuxWoUnFV',
    userPoolWebClientId: '392vsd1ue4879mqj1gia65boav',
  }
});

ReactDOM.render(
  <Provider store={store}>
  <React.StrictMode>
    <Router>
    <App />
    </Router>
  </React.StrictMode>
  </Provider>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
