import { SET_USER_GROUP, SET_SESSION, SET_ORG_NAME, SET_DRIVER }  from '../constants/sessionConstants';

const initialState = {
    orgname : "",
    drivername: "",
    usergroup:"",
    currentsession:"",
   
};


export const sessionReducers = (state = initialState, action) => {
    switch(action.type){
        case SET_SESSION:
            return {
                ...state,
                currentsession: action.payload
            }
        case SET_USER_GROUP:
            return {
                ...state,
                usergroup: action.payload
            }
        case SET_ORG_NAME:
            return {
                ...state,
                orgname: action.payload
            }
        case SET_DRIVER:
            return {
                ...state,
                drivername: action.payload
            }
        default:
            return state;
    }
}


