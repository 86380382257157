import React from "react";
import "./CardComponent.css";
import Alarm from "@material-ui/icons/Alarm";
import FavoriteIcon from "@material-ui/icons/Favorite";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import  grey from "@material-ui/core/colors/grey";
import Grid from "@material-ui/core/Grid";
import {Legend1} from "../Dashboard/Legend";

export default function SummaryCard(props) {
  //Data from DynamoDB
  //This was hard coded as some of information does not exist.
  //Please make sure to change the input data name in the future
  //According to DynamoDB.
  let predvalue1;
  let predvalue2;
  {(props.data.heart_rate_prediction_1hr && props.data.heart_rate_prediction_1hr !== "NO_BASELINE_DATA") ? (predvalue1 = props.data.heart_rate_prediction_1hr) : (predvalue1 = "0")}
  {(props.data.heart_rate_prediction_2hr && props.data.heart_rate_prediction_2hr !== "NO_BASELINE_DATA") ? (predvalue2 = props.data.heart_rate_prediction_2hr) : (predvalue2 = "0")}
  const cards = [
    {
      Fatigue_Score: {
        value:  '0', //props.data.fatigue_score,
        compare: "#D7D7D7",
      },
    },
    {
      Heart_Rate: {
        value: props.data.heart_rate,
        compare: props.data.heart_rate_compare,
      },
    },
    {
      RMSSD: {
        value: Math.round(props.data.rmssd * 100) / 100,
        compare: props.data.rmssd_compare,
      },
    },
    {
      Distance: {
        value: Math.round((props.data.estimate_distance * 100) / 1000) / 100,
        compare: null,
      },
    },
    {
      Prediction_in_One_hour: {
        value: predvalue1,
        compare: null,
      },
    },
    {
      Prediction_in_Two_hour: {
        value: predvalue2,
        compare: null,
      },
    },
    {
      Duration: {
        value: Math.round((props.data.elapsed_time * 100) / 60) / 100,
        compare: null,
      },
    },
    {
      Heart_Rate_Variability: {
        value: '0', //props.data.heart_rate_variability_score,
        compare: null,
      },
    },
    {
      Minimum_Heart_Rate: {
        value: Math.round(props.data.minimum_heart_rate * 100) / 100,
        compare: null,
      },
    },
    {
      Maximum_Heart_Rate: {
        value: Math.round(props.data.maximum_heart_rate * 100) / 100,
        compare: null,
      },
    },
    {
      Average_Heart_Rate: {
        value: Math.round(props.data.avg_heart_rate * 100) / 100,
        compare: null,
      },
    },
    {
      Average_RMSSD: {
        value: Math.round(props.data.avg_rmssd * 100) / 100,
        compare: null,
      },
    },
    {
      Prediction: {
        value: '0.0-0.0', //props.data.prediction,
        compare: props.data.prediction_compare,
      },
    },
    {
      Next_Break_Time: {
        value: props.data.next_break_time,
        compare: null,
      },
    },
  ];

  return (
    <div>
      {/* {console.log("PC")} */}
      <div id="legend">
      <h2>Latest Record of {props.data.session}</h2>
      <Legend1/>
      </div>
      <div>
        <Grid>
          <div id="summary-container">
            {cards.map((card, index) => {
              if (index === 12 || index === 13) {
                return "";
              }
              /* spacing and captalizing */
              let category = Object.keys(card)[0];
              let renderImage;
              let unit = "bpm";
              let compare = "#ffffff";
              let name = category.replace(/_/g, " ");
              name = name.replace(/\b(\w)/g, (s) => s.toUpperCase());
              const found2 = (/^(\d+\.\d+-\d\d\.\d+)$/.test(card[category].value));
              const found1 = (/^(\d+\.\d+-\d\d\d\.\d+)$/.test(card[category].value));

              if (card[category].compare) {
                // Fatigue Score tile colour.
                if (card[category].compare === "#D7D7D7") {
                  compare = "#D7D7D7";
                } else if (card[category].compare === "OUTLIER_DETECTED" || card[category].compare === "OUTSIDE_LOW" || card[category].compare === "OUTSIDE_HIGH") {
                  compare = "#FD8686";
                } else if (
                  card[category].compare === "UPPER_QRT" ||
                  card[category].compare === "LOWER_QRT"
                ) {
                  compare = "#FFD07B";
                } else if (card[category].compare === "IN_RANGE") {
                  compare = "#50DDB6";
                } else if (card[category].compare === "NO_BASELINE_DATA") {
                  compare = "#DDA0DD";
                } 
              }

              //Decide on the Icon to use for each tile and it's color.
              // Choose unit for the tile.
              if (name === "Distance") {
                renderImage = (
                  <LocalShippingIcon
                    fontSize="large"
                    style={
                      compare !== "#ffffff"
                        ? { color: "#ffffff" }
                        : { color: grey[400] }
                    }
                  />
                );
                unit = "km";
              } else if (name === "Duration") {
                renderImage = (
                  <Alarm
                    fontSize="large"
                    style={
                      compare !== "#ffffff"
                        ? { color: "#ffffff" }
                        : { color: grey[400] }
                    }
                  />
                );
                unit = "min";
              } else if (name === "Fatigue Score") {
                renderImage = (
                  <HelpOutlineIcon
                    fontSize="large"
                    style={
                      compare !== "#ffffff"
                        ? { color: "#ffffff" }
                        : { color: grey[400] }
                    }
                  />
                );
                unit = "";
              } else {
                renderImage = (
                  <FavoriteIcon
                    fontSize="large"
                    style={
                      compare !== "#ffffff"
                        ? { color: "#ffffff" }
                        : { color: grey[400] }
                    }
                  />
                );

                if (name === "Heart Rate Variability") {
                  unit = "%";
                } else if (name === "RMSSD" || name === "Average RMSSD") {
                  unit = "ms";
                }
              }
              return (
                <div
                  key={category}
                  className="summarycard-container"
                  style={compare ? { backgroundColor: compare } : {}}
                >
                  <div wrap="no wrap" className="summary-card-top">
                    <span className="summary-card-name">{name}</span>
                    <span> {renderImage} </span>
                  </div>
                  <div wrap="no wrap" className={"summary-card-bottom" + (name.includes("Prediction") ? ("-prediction") : (""))}>
                    <span
                      className="summary-card-unit"
                      style={compare !== "#ffffff" ? { color: "#FFFFFF" } : {}}
                    >
                      {unit}
                    </span>
                    <span className={"summary-card-value" + (found1 ? ("-longprediction") : (found2 ? ("-prediction") : ("")))}>
                      {card[category].value}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </Grid>
      </div>
    </div>
  );
}
