export const USER_SIGNED_IN = 'USER_SIGNED_IN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const SET_USER_GROUP = 'SET_USER_GROUP';
export const GET_PERMISSION = 'GET_PERMISSION';
export const SET_ORG_TOKEN = 'SET_ORG_TOKEN';
export const SET_DRIVER_TOKEN = 'SET_DRIVER_TOKEN';

export const FETCH_ORG = 'FETCH_ORG';
export const FETCH_DRIVER = 'FETCH_DRIVER';
export const FETCH_SESSION = 'FETCH_SESSION';
export const FETCH_DATE = 'FETCH_DATE';
export const FETCH_TIME = 'FETCH_TIME';
export const FETCH_FCAM = 'FETCH_FCAM';
export const FETCH_ECG = 'FETCH_ECG';
export const FETCH_MAP = 'FETCH_MAP';
export const FETCH_IMAGES = 'FETCH_IMAGES';
export const FETCH_ONE_ECG = 'FETCH_ONE_ECG';
export const FETCH_REQUEST = 'FETCH_REQUEST';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_FAIL = 'FETCH_FAIL';
export const FETCH_PROFILE = 'FETCH_PROFILE';

export const CLEAR_DRIVER = 'CLEAR_DRIVER'
export const CLEAR_SESSION = 'CLEAR_SESSION'


