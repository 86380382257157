import React from 'react';
import Profile from '../components/Profile/Profile';
function ProfileScreen() {
    return (
        <div>
        <Profile />
       
        </div>
    );
}

export default ProfileScreen;