import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Auth } from "aws-amplify";
import "./Profile.css";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";

export default function EditProfile(props) {
  const { openPopup, setOpenPopup } = props;
  const [fullName, setFullName] = useState("");
  const [nickName, setNickName] = useState("");
  const [phone, setPhone] = useState("");

  const theme = createTheme({
    overrides: {
      MuiFormControl: {
        root: {
          margin: "5px",
        }
      },
      MuiButton: {
        root: {
          margin: "5px"
        }
      }
    }
  });

  const fetchData = async () => {
    const data = await Auth.currentAuthenticatedUser();
    setNickName(data.attributes["custom:nickname"]);
    setFullName(data.attributes["custom:fullname"]);
    setPhone(data.attributes["custom:phonenumber"]);
  };

  useEffect(() => {
    fetchData();
  }, [openPopup]);

  return (
    <Dialog open={openPopup} fullWidth maxWidth="md">
      <DialogTitle>
        <div>Edit Profile</div>
      </DialogTitle>
      <DialogContent>
        <div>
        <MuiThemeProvider theme={theme}>
          <div className = "profileEditInputFields">
          <TextField
            id="outlined-name"
            label="Name"
            value={fullName}
            onChange={(event) => setFullName(event.target.value)  }
            variant="outlined"
          />
          <TextField
            id="outlined-name"
            label="NickName"
            value={nickName}
            onChange={(event) => setNickName(event.target.value)}
            variant="outlined"
          />
          <TextField
            id="outlined-name"
            label="Phone"
            value={phone}
            onChange={(event) => setPhone(event.target.value)}
            variant="outlined"
          />
          </div>
          <span className = "profileEditButtons">
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.updateProfile(fullName, nickName, phone)}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenPopup(false)}
          >
            Cancel
          </Button>
          </span>
          </MuiThemeProvider>
        </div>
      </DialogContent>
    </Dialog>
  );
}
