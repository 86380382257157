import React from "react";
import TabContext from "@material-ui/lab/TabContext";
import TabPanel from "@material-ui/lab/TabPanel";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core";
import DayLineChart from "./DayLineChart";
import "./DashboardTabMobile.css";
import EcgLineChart from "./EcgLineChart";
import MapTracker from "./MapTracker";
import  grey from "@material-ui/core/colors/grey";
import ArrowBackIosSharpIcon from "@material-ui/icons/ArrowBackIosSharp";
import SummaryCardMobile from '../Card/SummaryCardMobile';
import DashboardDetailMobile from "./DashboardDetailMobile";

export default function DashboardSummaryMobile(props) {
  const [value, setValue] = React.useState("1");
  var mapData;
  var jsonData;
  const useStyles = makeStyles((theme) => ({
    containerMobileSummary: {
      padding: "20px",
      alignItems: "center",
      justifyContent: "center",
    },
    containerMobile: {
      padding: "0px",
      paddingTop: "13px",
      alignItems: "center",
      justifyContent: "center",
      
    },
    containerMap: {
      padding: "0px",
      paddingTop: "18px",
      alignItems: "center",
      justifyContent: "center",
    },
    graphCard: {
      minWidth: "70%",
      maxWidth: "100%",
      position: "relative",
      backgroundColor: "#fff",
    },
  }));
  const classes = useStyles();


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function backToSummaryButton() {
    return(
      <>
      <ArrowBackIosSharpIcon
       id="toSummaryOverview"
       fontSize="medium"
       style={{ color: grey[400] }}
       onClick={()=>setValue("1")}
     />
     <h4 className="sessionTitleMobile">{props.data.session}</h4></>
    )
  }

  return (
    <Box>
      <TabContext value={value}>
        <Box>
          <TabPanel value="1" classes={{ root: classes.containerMobileSummary }}> 
            <Box>
              {<SummaryCardMobile data={props.data} onSelectDetail={setValue}/>}
            </Box>
          </TabPanel>
          <TabPanel value="2" classes={{ root: classes.containerMobile }}>
          <Box>
            {backToSummaryButton()}
              <div className="wrapper-mobile">
                <div className={classes.graphCard}>
                  <span className="graph-y-label">Daily Heart Rate and RMSSD</span>
                  <span className="graph-x-label">Time (hour)</span>
                  <div className = {"scrollable-horizontal"}>
                    <DayLineChart />
                  </div>
                  <DashboardDetailMobile data={props.data} detailOf={"DayLine"}/>
                </div>
              </div>
            </Box>
          </TabPanel>
          <TabPanel value="3" classes={{ root: classes.containerMobile }}>
          <Box>
            {backToSummaryButton()}
              <div className="wrapper-mobile">
                <div className={classes.graphCard}>
                  <span className="graph-y-label">ECG Amplitude (mV)</span>
                  <span className="graph-x-label">Time (second)</span>
                  <div className = {"scrollable-horizontal"}>
                    <EcgLineChart/>
                  </div>
                  <DashboardDetailMobile data={props.data} detailOf={"ECG"}/>
                </div>
              </div>
            </Box>
          </TabPanel>
          <TabPanel value="4" classes={{ root: classes.containerMap }}>
          <Box>
            {backToSummaryButton()}
              <div className="wrapper-mobile">
                <div>
                  <MapTracker />
                </div>
                <DashboardDetailMobile data={props.data} detailOf={"map"}/>
              </div>
            </Box>
          </TabPanel>
        </Box>
      </TabContext>
    </Box>
  );
}
