import React from "react";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import "./ComboBox.css";

function ComboBox(props) {
 
  const orgProps = props.selectedDB.map((option) => option.o_name)
  const deviceProps = props.selectedDB.map((option) => option.device_id)
  const sessionProps = props.selectedDB.map((option) => option.session)

  const currentProps = (props.title === "Organisation: " ? orgProps : (props.title === "DriverID: " ? deviceProps : sessionProps))

  return (
    <div className = "combo-box-search-outline">
      <Autocomplete
          id="combo-box-search"
          freeSolo
          onChange={(event, newValue) => {
            props.inputValueChange(newValue);
          }}
          filterSelectedOptions
          options={currentProps}
          renderInput={(params) => (
            <TextField {...params} label={props.title} variant="standard"  />
          )}
        />
    </div>
  );
}

export default ComboBox;
