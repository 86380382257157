/**
 * This page is only allow to open for (cognito:groups = ['KJR', 'org_admin', 'drivers'] && custom:device === 'device_id')
 *
 */

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchSession, fetchDate, fetchSessionFiltered, fetchSessionMore, fetchSessionFilteredMore } from "../actions/authActions";
import Dashboard from "./Dashboard/Dashboard";
import LoadingBox from "./LoadingBox";
import SessionboardCard from "./Card/SessionboardCard";
import "./SelectSession.css";
import { SET_SESSION } from "../constants/sessionConstants";
import { CLEAR_DRIVER } from "../constants/authConstants";
import ComboBox from './ComboBox';
import {Legend1} from './Dashboard/Legend';

const SelectSession = (props) => {
  const { history } = props;
  const authInfo = useSelector((state) => state.authInfo);
  const sessionInfo = useSelector((state) => state.sessionInfo);
  const dailyData = useSelector((state) => state.authInfo.dateDB);
  const { orgname, drivername } = sessionInfo;
  const { sessionDB, loading } = authInfo;
  const [openPopup, setOpenPopup] = useState(false);
  const [session, setSession] = useState("");
  const dispatch = useDispatch();
  const [childData, setChildData] = useState("");
  const [loadMoreCount, setLoadMoreCount] = useState(14);
  const [hasMore, setLoadMoreItems] = useState(undefined);
  
  let more = "Load More Sessions..."
  let less = "Load Less Sessions..."

  useEffect(() => {
    async function fetchItems() {
    // window.addEventListener("resize", updateDimensions);
    try {
      if (!childData) {
        let hasMoreItems = await dispatch(fetchSession(orgname, drivername));
        setLoadMoreItems(hasMoreItems)
      } else {
        let hasMoreItems = await dispatch(fetchSessionFiltered(orgname, drivername, childData));
        setLoadMoreItems(hasMoreItems)
      }
      //setSession(sessionDB[0]); //Doesn't produce error anymore without default session selected.
    } catch (e) {
      alert(e);
      history.goBack();
    }
    dispatch({type: CLEAR_DRIVER, payload: []});}
    fetchItems();
  }, [dispatch, history, orgname, drivername, childData]);

  async function loadMoreItems() {
    try {
      if (sessionDB.length - 2 < loadMoreCount) {
        if (!childData) {
          let hasMoreItems = await dispatch(fetchSessionMore(orgname, drivername, sessionDB));
          setLoadMoreItems(hasMoreItems)
        } else {
          let hasMoreItems = await dispatch(fetchSessionFilteredMore(orgname, drivername, childData, sessionDB));
          setLoadMoreItems(hasMoreItems)
        }
      }
      setLoadMoreCount(loadMoreCount + 14)
    } catch (e) {
      alert(e);
    }
  }

  function sessionSelected(idx) {
    history.replace({ pathname: `${sessionDB[idx].session}` });
    dispatch(fetchDate(orgname, drivername, sessionDB[idx].session));
    dispatch({type: SET_SESSION, payload: sessionDB[idx].session})
    setSession(sessionDB[idx]);
    setOpenPopup(true);
  }

  return (
    <div className="sessionDB">
      <div className="get_session">
        {<ComboBox inputValueChange={setChildData} selectedDB = {sessionDB} id="sessionDBList" title="Session: "/>}
      </div>
      {loading ? (
        <LoadingBox></LoadingBox>
      ) : (
        <div>
        <div id="legend-session">
          Latest HR/RMSSD status:
          <Legend1/>
        </div>
        <div id="session-list">
          {openPopup ? (
            <Dashboard
              openPopup={openPopup}
              setOpenPopup={setOpenPopup}
              orgname={orgname}
              drivername={drivername}
              session={session}
            ></Dashboard>
          ) : (
            ""
          )}
          {sessionDB.map((data, idx) => {
            if (idx >= loadMoreCount) {
              return;
            }
            const session_data = {
              avg_hr : Math.round(data.avg_heart_rate),
              hr_compare :  data.heart_rate_compare,
              distance : Math.round(data.estimate_distance/1000), 
              sessionDate : data.session, 
              avg_rmssd : Math.round(data.avg_rmssd),
              rmssd_compare : data.rmssd_compare,
              fatigue_score : data.fatigue_score,
              prediction : data.heart_rate_prediction_1hr,
            }
            return (
              <div key={data.session}>
                <span action="true" onClick={() => sessionSelected(idx)}>
                  <SessionboardCard session_data={session_data} />
                </span>
              </div>
            );
          })}
        </div>
        <div>
          <span id="loadMore" onClick={() => loadMoreItems()}>{((hasMore !== undefined) || (sessionDB.length > loadMoreCount)) ? (more) : ("")}</span>
          <span id="loadMore" onClick={() => setLoadMoreCount(((loadMoreCount/14)-1)*14)}>{(14 < loadMoreCount) ? (less) : ("")}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectSession;
