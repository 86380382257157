import React, { useState, useEffect, useRef } from "react";
import * as d3 from "d3";
import { useSelector, useDispatch } from "react-redux";
import {
  FETCH_TIME,
  FETCH_FCAM,
  FETCH_DATE,
  FETCH_MAP,
  FETCH_ECG,
} from "../../constants/authConstants";
import { fetchDate } from "../../actions/authActions";
import "./LineChart.css";

const DayLineChart = () => {
  const dailyData = useSelector((state) => state.authInfo.dateDB);
  const sessionInfo = useSelector((state) => state.sessionInfo);
  const { orgname, drivername, currentsession } = sessionInfo;
  const ecgData = useSelector((state) => state.authInfo.ecgDB);
  const [selectedData, setselectedData] = useState(dailyData);
  const selectedData2 = useSelector((state) => state.authInfo.timeDB);
  const photoTime = useSelector((state) => state.authInfo.timeDB);
  const svgRef = useRef();
  const xAxisRef = useRef();
  const yAxisRef = useRef();
  const brushRef = useRef();
  const yGridRef = useRef();

  const dispatch = useDispatch();

  function UpdateTimeFromImage(dataSelection) {
    //this stops the ECG getting the last data or wrong data when doing its AWS calls; should set the ECG to the 1st image time
    //turned into a function for 3 different calls in this file
    //console.log("UpdateTimeFromImage");
    const NewfcamList = dataSelection.filter((d) => d.json_source === "FCAM");
    if(NewfcamList.length > 0){
      const ECGtime = new Date(NewfcamList[0].event_time);
      const photo = new Date(photoTime);
      if(photo.getTime() !== ECGtime.getTime()){
        //console.log("Updated Time!");
        dispatch({ type: FETCH_TIME, payload: ECGtime });
      }
    }
  }

  useEffect(() => {

    const width = 1370;
    const height = 520;
    const margin = { left: 70, top: 50, right: 50, bottom: 50 };

    const svg = d3
      .select(svgRef.current)
      .classed("svg-container", true) 
      .attr("viewBox", `0 0 ${width} ${height}`)
      .classed("svg-content-responsive", true);

    svg
      .append("defs")
      .append("clipPath")
      .attr("id", "clip")
      .append("rect")
      .attr("width", width - margin.left - margin.right)
      .attr("height", height - margin.top - margin.bottom)
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    svg
      .append("text")
      .attr("class", "y_label")
      .attr("y", margin.top/2)
      .attr("x", 18)
      .attr("font-family", "Sans-serif")
      .attr("font-size", "32px")
      .attr("font-weight", "520")
      .attr("fill", "black")
      .text("Heart Rate (BPM)");
    
    svg
      .append("text")
      .attr("class", "x_label")
      .attr("x", (width-120) / 2)
      .attr("y", height - margin.bottom/2)
      .attr("font-family", "Sans-serif")
      .attr("font-size", "27px")
      .attr("font-weight", "520")
      .attr("fill", "black")
      .text("Time (hour)");
  }, []);

  useEffect(() => {
    const margin = { left: 70, top: 50, right: 50, bottom: 50 };
    const width = 1370;
    const height = 480;
    const innerHeight = height - margin.top - margin.bottom;
    const innerWidth = width - margin.left - margin.right;

    const svg = d3.select(svgRef.current);

    let brush = d3
      .brushX()
      .extent([
        [margin.left, margin.top],
        [width - margin.right, height - margin.bottom],
      ])
      .on("end", brushed);

    const xScale = d3
      .scaleTime()
      .domain(d3.extent(ecgData, (d) => d.event_time))
      .range([margin.left, margin.left + innerWidth]);

    const fixed_xScale = xScale.copy();

    const yScale = d3
      .scaleLinear()
      // .domain(d3.extent(ecgData, (d) => d.payload.ecg_analysis[0].heart_rate))
      .domain([
        d3.min(ecgData, (d) =>
          Math.min(
            d.payload.ecg_analysis[0].heart_rate,
            d.payload.ecg_analysis[0].RMSSD
          )
        ),
        d3.max(ecgData, (d) =>
          Math.max(
            d.payload.ecg_analysis[0].heart_rate,
            d.payload.ecg_analysis[0].RMSSD
          )
        ),
      ])
      .range([innerHeight + margin.top, margin.top]);

    const fixed_yScale = yScale.copy();

    const xAxis = d3.axisBottom(xScale);
    const yAxis = d3.axisLeft(yScale);

    const xAxisG = d3
      .select(xAxisRef.current)
      .attr("class", "x_axis")
      .attr("transform", `translate(${0}, ${margin.top + innerHeight})`)
      .style("font-size", "27px")
      .call(xAxis);

    const yAxisG = d3
      .select(yAxisRef.current)
      .attr("class", "y_axis")
      .attr("transform", `translate(${margin.left}, ${0})`)
      .style("font-size", "27px")
      .call(yAxis);

      
    function make_y_axis() {		
        return d3.axisLeft(yScale);
      }

    let yGridG = d3.select(yGridRef.current)
      .attr("class", "grid")
      .attr("transform", "translate(" + (71) + ",0)")
      .call(make_y_axis()
          .tickSize(-1250, 0, 0)
          .tickFormat("")
      )
      
    const draw_h_line = d3
      .line()
      .x((d) => xScale(d.event_time))
      .y((d) => yScale(d.payload.ecg_analysis[0].heart_rate));

    const draw_r_line = d3
      .line()
      .x((d) => xScale(d.event_time))
      .y((d) => yScale(d.payload.ecg_analysis[0].RMSSD));

    const hLine = svg
      .selectAll(".hLine")
      .data([ecgData.filter((d) => d.payload.ecg_analysis[0].heart_rate !== 0)])
      .join("path")
      .attr("class", "hLine")
      .attr("d", (d) => draw_h_line(d))
      .attr("stroke", "steelblue")
      .attr("stroke-width", 2)
      .attr("fill", "none")
      .attr("clip-path", "url(#clip");

    const rLine = svg
      .selectAll(".rLine")
      .data([ecgData.filter((d) => d.payload.ecg_analysis[0].RMSSD !== 0)])
      .join("path")
      .attr("class", "rLine")
      .attr("d", (d) => draw_r_line(d))
      .attr("stroke", "red")
      .attr("stroke-width", 2)
      .attr("fill", "none")
      .attr("clip-path", "url(#clip");

    d3.select(brushRef.current).call(brush);

    function brushed({ selection }) {
      if (!selection) {
        xScale.domain(fixed_xScale.domain());
        setselectedData(dailyData);
        dispatch(fetchDate(orgname, drivername, currentsession));
      } else {
        let [x1, x2] = [
          xScale.invert(Math.min(selection[0], selection[1])),
          xScale.invert(Math.max(selection[0], selection[1])),
        ];
        setselectedData(
          dailyData.filter((d) => d.event_time >= x1 && d.event_time <= x2)
        );
        const timelist = dailyData.filter(
          (d) => d.event_time >= x1 && d.event_time <= x2
        );
        const fcamList = timelist.filter((d) => d.json_source === "FCAM");
        const mapList = timelist.filter((d) => d.gps_raw !== null);

        dispatch({ type: FETCH_DATE, payload: timelist });
        if (window.innerWidth < 820) {
          //Nothing
        }
        else {
          dispatch({ type: FETCH_FCAM, payload: fcamList });
          dispatch({ type: FETCH_MAP, payload: mapList });
        }
        // dispatch({type: FETCH_TIME, payload: dailyData.filter((d) => d.event_time >= x1 && d.event_time <= x2)})
        //this resets the "times" for the ECG chart. otherwise you have to click on the picture before the ECG loads
        UpdateTimeFromImage(timelist);
        xScale.domain(selection.map(xScale.invert, fixed_xScale));
      }

      xAxisG.transition().duration(500).call(xAxis);

      svg.selectAll(".hLine").transition().duration(500).attr("d", draw_h_line);
      svg.selectAll(".rLine").transition().duration(500).attr("d", draw_r_line);

      d3.select(brushRef.current).call(d3.brushX().clear);
  }
  }, [ecgData]);

  return (
      <svg id = "line-chart-mobile" ref={svgRef}>
        <g ref={xAxisRef}></g>
        <g ref={yAxisRef}></g>
        <g ref={yGridRef}></g>
        <g ref={brushRef} className="brush"></g>
      </svg>
  );
};

export default DayLineChart;
