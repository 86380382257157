import React from "react";
import { Spinner } from 'react-bootstrap'

export default function LoadingBox() {
  return (
    <Spinner className="loading-circle" animation="border" role="status" aria-hidden="true"
    style={{width: '5rem', height: '5rem', color: '#92b5f8', alignItems: 'center',}}>
    </Spinner>
  );
}
