import {applyMiddleware, createStore, compose, combineReducers} from 'redux';
import thunk from 'redux-thunk';
import { sessionReducers } from '../reducers/sessionReducers';
import { authReducers } from '../reducers/authReducers';

const initialState = {}


const appReducer = combineReducers({
    sessionInfo: sessionReducers,
    authInfo: authReducers, 
});

const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
      return appReducer(undefined, action)
    }
  
    return appReducer(state, action)
  }

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, initialState, composeEnhancer(applyMiddleware(thunk)));

export default store;
