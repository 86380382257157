/**
 * This page is only allow to cognito:groups = ["KJR"]
 * Scan Organisation_list_table
 * Select Organisation
 * Pass selected organisation to SelectDriver.js
 *
 */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ListGroup } from "react-bootstrap";
import  grey from "@material-ui/core/colors/grey";
import ArrowBackIosSharpIcon from "@material-ui/icons/ArrowBackIosSharp";
import { SET_ORG_NAME } from "../constants/sessionConstants";
import { fetchOrg, fetchOrgFiltered } from "../actions/authActions";
import LoadingBox from './LoadingBox';
import { CLEAR_DRIVER, CLEAR_SESSION } from "../constants/authConstants";
import ComboBox from './ComboBox';
import "./SelectOrg.css";

const SelectOrg = (props) => {
  const { history } = props;
  const authInfo = useSelector((state) => state.authInfo);
  const { orgDB, loading } = authInfo;
  const [childData, setChildData] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    try {
      if (!childData) {
        dispatch(fetchOrg());
        console.log(childData)
      }
      else {
        dispatch(fetchOrgFiltered(childData));
        console.log(childData)
      }
    } catch (e) {
      alert("no data exists");
      history.goBack();
    }
    dispatch({type: CLEAR_SESSION, payload: []});
    dispatch({type: CLEAR_DRIVER, payload: []});
  }, [dispatch, history, childData]);

    function orgSelected(idx) {
    dispatch({ type: SET_ORG_NAME, payload: orgDB[idx].o_name });
    history.push({ pathname: `${orgDB[idx].o_name}/selectdriver` });
  }

  return (
    <div className="orglist">
      <ArrowBackIosSharpIcon
        id="back"
        style={{ color: grey[400] }}
        onClick={() => history.goBack()}
      />
      <div className="get_Org">
          {<ComboBox inputValueChange={setChildData} selectedDB = {orgDB} id="orgDBList" title="Organisation: "/>}
      </div>
    {loading ? <LoadingBox></LoadingBox>
    :
    <div id="list-container">
      {orgDB.map((data, idx) => {
        return (
          <div key={data.o_name}>
            <ListGroup.Item action onClick={() => orgSelected(idx)}>
              {data.o_name}
            </ListGroup.Item>
          </div>
        )
      })}
    
    </div>
    }
    </div>
  );
};

export default SelectOrg;
