/**
 * This page is only allow to open (cognito:groups = [KJR, org_admin] && custom:organisation === 'organisation')
 * Scan organisation table(list of drivers) which is belong to user.
 * Select driver and pass selected driver to SelectSession.js
 *
 */

import React, { useEffect, useState } from "react";
import { ListGroup } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import  grey from "@material-ui/core/colors/grey";
import ArrowBackIosSharpIcon from "@material-ui/icons/ArrowBackIosSharp";
import { SET_DRIVER } from "../constants/sessionConstants";
import { CLEAR_SESSION } from "../constants/authConstants";
import { fetchDriver, fetchDriverFiltered } from "../actions/authActions";
import LoadingBox from "./LoadingBox";
import "./SelectDriver.css";
import ComboBox from './ComboBox';

const SelectDriver = (props) => {
  const { history } = props;
  const orgname = props.match.params.orgname;
  const authInfo = useSelector((state) => state.authInfo);
  const { driverDB, loading } = authInfo;
  const dispatch = useDispatch();
  const [childData, setChildData] = useState("");

  useEffect(() => {
    try {
      if (!childData) {
        dispatch(fetchDriver(orgname));
      }
      else {
        dispatch(fetchDriverFiltered(orgname, childData));
      }
    } catch (e) {
      alert("no data exists");
      history.goBack();
    }
    dispatch({type: CLEAR_SESSION, payload: []});
  }, [orgname, childData, dispatch, history]);

  function driverSelected(idx) {
    dispatch({ type: SET_DRIVER, payload: driverDB[idx].device_id });
    history.push({ pathname: `${driverDB[idx].device_id}/selectsession` });
  }

  return (
    <div className="driverlist">
      <ArrowBackIosSharpIcon
        id="back"
        style={{ color: grey[400] }}
        onClick={() => history.goBack()}
      />
      <div className="get_Drivers">
            {<ComboBox inputValueChange={setChildData} selectedDB = {driverDB} id="driverDBList" title="DriverID: "/>}
          </div>
      {loading ? (
        <LoadingBox></LoadingBox>
      ) : (
        <div id="list-container">
          
          {driverDB.map((data, idx) => {
            return (
              <div key={data.device_id}>
                <ListGroup.Item action onClick={() => driverSelected(idx)}>
                  <span className="driverslisted">{data.device_id}</span>
                </ListGroup.Item>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SelectDriver;
