import React from "react";
import "./DashboardDetailMobile.css";
import Grid from "@material-ui/core/Grid";

export default function DashboardDetailMobile(props) {
  //Data from DynamoDB
  //This was hard coded as some of information does not exist.
  //Please make sure to change the input data name in the future
  //According to DynamoDB.

  var cards = [];
  if (props.detailOf === "map") {
    cards = [{
      Duration: {value: Math.round((props.data.elapsed_time * 10) / 60) / 10}},
      {
      Distance: {value: Math.round((props.data.estimate_distance * 10) / 1000) / 10},
    }]
  }
  else if (props.detailOf === "ECG"){
    cards = [
      {
        Fatigue_Score: {
          value:  '0', //props.data.fatigue_score,
        },
      },
      {
        HRV: {
          value: '0', //props.data.heart_rate_variability_score,
        },
      }]
  }
  else if (props.detailOf === "DayLine"){
    cards = [
      {
        HR: {
          value: props.data.heart_rate,
          compare: props.data.heart_rate_compare,
        },
      },
      {
        RMSSD: {
          value: Math.round(props.data.rmssd * 100) / 100,
          compare: props.data.rmssd_compare,
          },
      },
      {
        Heart_Rate2: {
          average: Math.round(props.data.avg_heart_rate * 100) / 100,
          min: Math.round(props.data.minimum_heart_rate * 100) / 100,
          max: Math.round(props.data.maximum_heart_rate * 100) / 100,
        },
      },
      {
        RMSSD2: {
          average: Math.round(props.data.avg_rmssd * 100) / 100,
          min: Math.round(props.data.minimum_rmssd * 100) / 100,
          max: Math.round(props.data.maximum_rmssd * 100) / 100,
        },
      },
    ];
  }
  console.log(cards)

  return (
    <div>
      {/* <div id="Mobilelegend"> <Legend1/> </div> */}
      <div>
        <Grid>
          <div id="summary-container-detail-mobile">
            {cards.map((card, index) => {
              /* spacing and captalizing */
              let category = Object.keys(card)[0];
              let unit = "bpm";
              let compare = "#ffffff";
              let name = category.replace(/_/g, " ");
              name = name.replace(/2/g, "");
              name = name.replace(/\b(\w)/g, (s) => s.toUpperCase());
              let cardClassName = ""
              if (index === 0) {
                cardClassName = "1"
              } else if (index === 1) {
                cardClassName = "2"
              } else {
                cardClassName = "3"
              }

              if (card[category].compare) {
                // Fatigue Score tile colour.
                if (card[category].compare === "#D7D7D7") {
                  compare = "#D7D7D7";
                } else if (card[category].compare === "OUTLIER_DETECTED" || card[category].compare === "OUTSIDE_LOW" || card[category].compare === "OUTSIDE_HIGH" ) {
                  compare = "OUT OF RANGE";
                } else if (
                  card[category].compare === "UPPER_QRT" ||
                  card[category].compare === "LOWER_QRT"
                ) {
                  compare = "WITHIN_RANGE";
                } else if (card[category].compare === "IN_RANGE") {
                  compare = "BASELINE";
                }
              }

              //Decide on the Icon to use for each tile and it's color.
              // Choose unit for the tile.
              if (name === "Distance") {
                unit = "km";
              } else if (name === "Duration" || name === "Next Break Time") {
                unit = "min";
              } else if (name === "Fatigue Score") {
                unit = "";
              } else if (name === "RMSSD") {
                unit = "ms";
              } else {
                if (name === "HRV") {
                  unit = "%";
                } 
              }
              return (
                <div
                  key={category}
                  className={"summarycard-detail-container-mobile" + cardClassName}
                >
                  {cardClassName !== "3" ? (
                    <div className={"detail-whole-mobile" + cardClassName}>
                    <div className={"detail-nameunit-mobile" + cardClassName} wrap="no wrap">
                      <span>{name}</span>
                      <span> {unit ? " ("+unit+")" : ""}</span>
                    </div>
                    <div className={"summary-detail-card-value-mobile"+ cardClassName}>
                      {card[category].value}
                    </div>
                    <div>
                    {props.detailOf === "DayLine" ? ((compare !== "#ffffff") ? compare : "")  : ""}
                    </div>
                  </div>
                  ) : (
                    <div className={"summarycard-detail-container-mobile" + cardClassName}>
                      <span>{name}</span>
                    <div className={"detail-container-mobile" + cardClassName}>
                    <div className={"detail-whole-mobile" + cardClassName}>
                      <div className={"summary-detail-card-value-mobile"+ cardClassName}>
                        {card[category].average}
                      </div>
                      <div className={"detail-nameunit-mobile" + cardClassName} wrap="no wrap">
                        <span>Average</span>
                      </div>
                    </div>
                    <div className={"detail-whole-mobile" + cardClassName}>
                      <div className={"summary-detail-card-value-mobile"+ cardClassName}>
                        {card[category].min}
                      </div>
                      <div className={"detail-nameunit-mobile" + cardClassName} wrap="no wrap">
                        <span>Min</span>
                      </div>
                    </div>
                    <div className={"detail-whole-mobile" + cardClassName}>
                      <div className={"summary-detail-card-value-mobile"+ cardClassName}>
                        {card[category].max}
                      </div>
                      <div className={"detail-nameunit-mobile" + cardClassName} wrap="no wrap">
                        <span>Max</span>
                      </div>
                    </div>
                    </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </Grid>
      </div>
    </div>
  );
}
