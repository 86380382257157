import React, { useEffect, useState } from "react";
import "./Profile.css";
import AWS from "aws-sdk";
import { Auth } from "aws-amplify";
import userImage from "../../../public/image/user.png";
import { Button } from "react-bootstrap";
import Tooltip from "@material-ui/core/Tooltip";
import EditProfile from "./EditProfile";
import dotenv from "dotenv";
import { useSelector, useDispatch } from 'react-redux';
import { fetchProfileImage } from "../../actions/authActions";

function Profile() {
  dotenv.config();

  const [sub, setSub] = useState("");
  const [fullName, setFullName] = useState("");
  const [nickName, setNickName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const [profileImg, setprofileImg] = useState(userImage);
  const [openPopup, setOpenPopup] = useState(false);

  const authInfo = useSelector((state) => state.authInfo);
  const { profileToken } = authInfo;
  const dispatch = useDispatch();

  const imageHandler = async (e) => {
    let reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setprofileImg(reader.result);
      }
    };
    if (e.target.files[0]){
      reader.readAsDataURL(e.target.files[0]);
      const dataUser = await Auth.currentAuthenticatedUser();
      let cred = await Auth.currentUserCredentials();
      AWS.config.update({
        region: "ap-southeast-2",
        accessKeyId: cred.accessKeyId,
        secretAccessKey: cred.secretAccessKey,
        sessionToken: cred.sessionToken,
      });

      // create_folder(sub)

      let upload = new AWS.S3.ManagedUpload({
        params: {
          Bucket: "fatiguem8-profile-photo",
          Key: `${sub}/profile.jpg`,
          Body: e.target.files[0],
        }
      });
      upload.send(function(err, data) {
        if (err === null){
          alert("upload successful");
          dispatch(fetchProfileImage(dataUser.username));
        }
        else {
          alert("upload failed");
        }
      })
    }
  };

  // fecth user's detail information
  useEffect(() => {
    const fetchData = async () => {
      const data = await Auth.currentAuthenticatedUser();
      setSub(data.attributes["sub"]);
      setEmail(data.attributes["email"]);
      setNickName(data.attributes["custom:nickname"]);
      setFullName(data.attributes["custom:fullname"]);
      setPhone(data.attributes["custom:phonenumber"]);
      dispatch(fetchProfileImage(data.username));
    };

    fetchData();
  }, [dispatch]);

  async function updateProfile(fullname, nickname, phone) {
    fullname !== ""? setFullName(fullname): console.log()
    nickname !== "" ? setNickName(nickname) : console.log()
    phone !== "" ? setPhone(phone) : console.log()
    setOpenPopup(false);

    // update profile details on cognito custom attributes
    const userFromCognito = await Auth.currentAuthenticatedUser();
    userFromCognito.Session = userFromCognito.signInUserSession;
    await Auth.updateUserAttributes(userFromCognito, {
      "custom:fullname": fullname,
      "custom:nickname": nickname,
      "custom:phonenumber": phone,
    });
  }

  return (
      <div className="profile-cardbody">
          <div className="col-sm-12 profile-image">
            <div className="image-upload">
              <label htmlFor="file-input">
                <Tooltip title="change-image" arrow>
                  {profileToken ? (
                    <img
                    className="avatar-image"
                    alt="profile"
                    src={`data:image/jpeg;base64,${Buffer.from(profileToken).toString("base64")}`}
                    // src = {"https://fatiguem8-profile-photo.s3.ap-southeast-2.amazonaws.com/${drivername}/profile.jpg"}
                    />
                  ) : (
                    <img
                    className="avatar-image"
                    src={profileImg}
                    alt="profile"
                  ></img>
                  )}
                  
                </Tooltip>
              </label>
              <input
                id="file-input"
                type="file"
                accept="image/*"
                onChange={imageHandler}
              />
            </div>
          </div>
          <div className="profile-text">
            <div className="profile-textfield">
              <div className="primary-textfield">Full Name</div>
              <div className="secondary-textfield">
                {fullName ? fullName : "Your Name"}
              </div>
            </div>
            <div className="profile-textfield">
              <div className="primary-textfield">Nickname</div>
              <div className="secondary-textfield">
                {nickName ? (
                  nickName
                ) : (
                  "Your Nickname"
                )}
              </div>
            </div>
            <div className="profile-textfield">
              <div className="primary-textfield">Phone</div>
              <div className="secondary-textfield">
                {phone ? phone : "Your Phone Number"}
              </div>
            </div>
            <div className="profile-textfield">
              <div className="primary-textfield">Email</div>
              <div className="secondary-textfield">
                {email ? email : "Please Save Your Email"}
              </div>
            </div>
          </div>
        <Button className="edit-button" onClick={() => setOpenPopup(true)}>
          Edit
        </Button>

        <EditProfile
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          updateProfile={updateProfile}
        ></EditProfile>
    </div>
  );
}

export default Profile;
