import { AppBar, Toolbar, Typography, Avatar, Button } from "@material-ui/core";
import { useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';
import { useHistory } from "react-router";
import "./AppHeader.css";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";

const AppHeader = () => {
  const authInfo = useSelector((state) => state.authInfo);
  const { profileToken } = authInfo;

  const theme = createTheme({
    overrides: {
      MuiToolbar: {
        regular: {
          '@media(max-width:420px)' : {
            minHeight:"48px"
          }
        },
        gutters: {
          '@media(max-width:420px)' : {
            padding:"12px",
          },
          '@media(max-width:300px)' : {
            padding:"8px",
          }
        }
      }
    }
  });

    const signedIn = useSelector((state) => state.authInfo.signedIn);
    const history = useHistory();

    const [mobileSize, setMobileSize] = useState(window.matchMedia("(max-width: 420px)").matches)

    useEffect(() => {
      window.matchMedia("(max-width: 420px)").addEventListener('change', e => setMobileSize( e.matches ));

      return () => window.matchMedia("(max-width: 420px)").removeEventListener('change', e => setMobileSize( e.matches ));
    }, []);

    const routeChange = () =>{ 
      let path = `/profile`; 
      history.push(path);
    }
 
    //Function call from FatigueM8 typography click to home page.
    const toHome = () =>{ 
      let path = '/login'
      if (signedIn) {
        path = `/`; 
      }
      history.push(path);
    }

    // useEffect(() => {
    //   console.log("UPDATED")
    // }, [profileToken]);
  
  return (
    <MuiThemeProvider theme={theme}>
    <header className="App-header">
      <AppBar style={{ backgroundColor: "#92b5f8" }} className="nav" position="sticky">
        <Toolbar className="leftSide">
          <span className="appheader-home">
            <Typography variant="h4" id="logo" onClick={toHome}>
              FatigueM8
            </Typography>
          </span>
        </Toolbar>
        {signedIn && <Toolbar className="rightSide">
            <>
              {mobileSize ? ("") : (<span>Welcome </span>)}
              <span className = "appheader-avatar">
              {profileToken ? (
                <div>
                <img
                  id="appheader-profile"
                  alt="Profile"
                  onClick={routeChange}
                  src={`data:image/jpeg;base64,${Buffer.from(profileToken).toString("base64")}`}
                  // src = {"https://fatiguem8-profile-photo.s3.ap-southeast-2.amazonaws.com/{driverName}/profile.jpg"}
                />
              </div>
              ) : (
                <Avatar alt = "userGroups" onClick={routeChange}/>
              )}</span>
              <Button size="small" id="signOut" onClick={() => Auth.signOut()} > Sign Out </Button>
            </>
        </Toolbar>
        }
      </AppBar>
    </header>
    </MuiThemeProvider>
  );
}

export default AppHeader;
