import React from "react";
import "./Legend.css";

export var Legend1 = function() {
  return (
      <div className="legend-rows">
        <div className="legend-row">
          <div className="legend-box red"></div>
          <span>Out of Range</span>
        </div>

        <div className="legend-row">
          <div className="legend-box yellow"></div>
          <span>Within Range</span>
        </div>

        <div className="legend-row">
          <div className="legend-box green"></div>
          <span>Baseline</span>
        </div>

        <div className="legend-row">
          <div className="legend-box purple"></div>
          <span>No Baseline</span>
        </div>
        <div className="legend-row">
          <div className="legend-box black"></div>
          <span>no ECG data</span>
        </div>
    </div>
  );
}

export var Legend2 = function() {
  return (
      <div className="legend-rows2">
        <div className="legend-row">
          <div className="legend-box blue"></div>
          <span>HR</span>
        </div>

        <div className="legend-row">
          <div className="legend-box red2"></div>
          <span>RMSSD</span>
        </div>
    </div>
  );
}

