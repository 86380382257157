import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { timeFormat, timeParse } from "d3-time-format";
import { fetchOneEcgData } from "../../actions/authActions";
import * as d3 from "d3";
import "./LineChart.css";

const EcgLineChart = () => {
  const photoTime = useSelector((state) => state.authInfo.timeDB);
  const ecgData = useSelector((state) => state.authInfo.ecgDB);
  const sessionInfo = useSelector((state) => state.sessionInfo);
  const tenSecData = useSelector((state) => state.authInfo.oneEcgDB);
  const dispatch = useDispatch();
  const { drivername, currentsession } = sessionInfo;
  const svgEcgRef = useRef();
  const xEcgAxisRef = useRef();
  const yEcgAxisRef = useRef();
  const yEcgGridRef = useRef();

  useEffect(() => {
    let idx = -1;
    let compare = Infinity;
    let filename = [];

    for (let i = 0; i < ecgData.length; i++) {
      let currentTime = +photoTime;
      let nearestTime = +ecgData[i].event_time;
      let diff = Math.abs(currentTime - nearestTime);
      if (compare > diff) {
        compare = diff;
        idx = i;
        //filename = ecgData[i].payload.ecg_analysis[0].ECG_file_name;
      }
    }

    //console.log(idx + ", " + ecgData.length);
    //console.log(ecgData);
    if(idx !== -1 && ecgData.length > 0){
      if(idx > 2){
        filename.push(ecgData[idx - 3].payload.ecg_analysis[0].ECG_file_name);
      }
      if(idx > 1){
        filename.push(ecgData[idx - 2].payload.ecg_analysis[0].ECG_file_name);
      }
      if(idx > 0){
        filename.push(ecgData[idx - 1].payload.ecg_analysis[0].ECG_file_name);
      }
      filename.push(ecgData[idx].payload.ecg_analysis[0].ECG_file_name);
      if(idx < (ecgData.length)-1){
        filename.push(ecgData[idx + 1].payload.ecg_analysis[0].ECG_file_name);
      }
      if(idx < (ecgData.length)-2){
        filename.push(ecgData[idx + 2].payload.ecg_analysis[0].ECG_file_name);
      }
      //console.log(filename);
    }

    dispatch(fetchOneEcgData(drivername, currentsession, filename));
  }, [photoTime]);

  useEffect(() => {
    const width = 1370;
    const height = 520;
    const margin = { left: 50, top: 50, right: 50, bottom: 50 };
    const svg = d3
      .select(svgEcgRef.current)
      .classed("svg-container", true) 
      .attr("viewBox", `0 0 ${width} ${height}`)
      .classed("svg-content-responsive", true);

    svg
      .append("text")
      .attr("class", "y_label")
      .attr("y", margin.top/2)
      .attr("x", 18)
      .attr("font-family", "Sans-serif")
      .attr("font-size", "32px")
      .attr("font-weight", "520")
      .attr("fill", "black")
      .text("ECG Amplitude (mV)");
    
    svg
      .append("text")
      .attr("class", "x_label")
      .attr("x", (width-120) / 2)
      .attr("y", height - margin.bottom/2)
      .attr("font-family", "Sans-serif")
      .attr("font-size", "27px")
      .attr("font-weight", "520")
      .attr("fill", "black")
      .text("Time (Seconds)");
  }, []);

  useEffect(() => {
    const margin = { left: 70, top: 50, right: 50, bottom: 50 };
    const width = 1370;
    const height = 480;
    const innerHeight = height - margin.top - margin.bottom;
    const innerWidth = width - margin.left - margin.right;

    const svg = d3.select(svgEcgRef.current);

    const xScale = d3
      .scaleLinear()
      .domain(d3.extent(tenSecData, (d) => d.x))
      .range([margin.left, margin.left + innerWidth]);

    const yScale = d3
      .scaleLinear()
      // .domain([0, d3.max(tenSecData, (d) => d.y)])
      //.domain([d3.min(tenSecData, (d) => d.y), d3.max(tenSecData, (d) => d.y)])
      .domain([0, 260])
      .range([margin.top + innerHeight, margin.top]);

    const xAxis = d3.axisBottom(xScale);
    const yAxis = d3.axisLeft(yScale);

    const xAxisG = d3
      .select(xEcgAxisRef.current)
      .attr("class", "ecg_x_axis")
      .attr("transform", `translate(${0}, ${margin.top + innerHeight})`)
      .style("font-size", "27px")
      .call(xAxis);

    const yAxisG = d3
      .select(yEcgAxisRef.current)
      .attr("class", "ecg_y_axis")
      .attr("transform", `translate(${margin.left}, ${0})`)
      .style("font-size", "27px")
      .call(yAxis);
  
    function make_y_axis() {		
        return d3.axisLeft(yScale);
      }

    let yGridG = d3.select(yEcgGridRef.current)
      .attr("class", "grid")
      .attr("transform", "translate(" + (71) + ",0)")
      .call(make_y_axis()
          .tickSize(-1250, 0, 0)
          .tickFormat("")
      )

    const drawLine = d3
      .line()
      .x((d) => xScale(d.x))
      .y((d) => yScale(d.y));

    const ecgLine = svg
      .selectAll(".ecgLine")
      .data([tenSecData])
      .join("path")
      .attr("class", "ecgLine")
      .attr("d", (d) => drawLine(d))
      .attr("stroke", "steelblue")
      .attr("stroke-width", 2)
      .attr("fill", "none");      
  }, [tenSecData]);

  return (
    <svg id = "line-chart-mobile" ref={svgEcgRef}>
      <g ref={xEcgAxisRef}></g>
      <g ref={yEcgAxisRef}></g>
      <g ref={yEcgGridRef}></g>
    </svg>
  );
};

export default EcgLineChart;
