import React from "react";
import "./SummaryCardMobile.css";
import Alarm from "@material-ui/icons/Alarm";
import FavoriteIcon from "@material-ui/icons/Favorite";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import  grey from "@material-ui/core/colors/grey";
import Grid from "@material-ui/core/Grid";
import {Legend1} from "../Dashboard/Legend";

export default function SummaryCardMobile(props) {
  //Data from DynamoDB
  //This was hard coded as some of information does not exist.
  //Please make sure to change the input data name in the future
  //According to DynamoDB.
  let predvalue1;
  let predvalue2;
  {(props.data.heart_rate_prediction_1hr && props.data.heart_rate_prediction_1hr !== "NO_BASELINE_DATA") ? (predvalue1 = props.data.heart_rate_prediction_1hr) : (predvalue1 = "0")}
  {(props.data.heart_rate_prediction_2hr && props.data.heart_rate_prediction_2hr !== "NO_BASELINE_DATA") ? (predvalue2 = props.data.heart_rate_prediction_2hr) : (predvalue2 = "0")}
  const cards = [
    {
      Fatigue_Score: {
        value:  '0', //props.data.fatigue_score,
        compare: "#D7D7D7",
      },
    },
    {
      Heart_Rate_Variability: {
        value: '0', //props.data.heart_rate_variability_score,
        compare: null,
      },
    },
    {
      Average_Heart_Rate: {
        value: Math.round(props.data.avg_heart_rate * 10) / 10,
        compare: props.data.heart_rate_compare,
      },
    },
    {
      Duration: {
        value: Math.round((props.data.elapsed_time * 10) / 60) / 10,
        compare: null,
      },
    },
    {
      Next_Break_Time: {
        value: props.data.next_break_time,
        compare: null,
      },
    },
    {
      Prediction_in_One_hour: {
        value: predvalue1,
        compare: null
      },
    },
    {
      Prediction_in_Two_hour: {
        value: predvalue2,
        compare: null
      },
    },
    {
      Distance: {
        value: Math.round((props.data.estimate_distance * 10) / 1000) / 10,
        compare: null,
      },
    },
  ];

  return (
    <div>
      {/* {console.log("MOBILE")} */}
      <div id="Mobilelegend">
      <h4 className="session-mobile-title">{props.data.session}</h4>
      <Legend1/>
      </div>
      <div>
        <Grid>
          <div id="summary-container-mobile">
            {cards.map((card, index) => {
              /* spacing and captalizing */
              let category = Object.keys(card)[0];
              let renderImage;
              let unit = "bpm";
              let compare = "#ffffff";
              let value = "2";
              let name = category.replace(/_/g, " ");
              name = name.replace(/\b(\w)/g, (s) => s.toUpperCase());
              const found1 = (/^(\d+\.\d+-\d\d\.\d+)$/.test(card[category].value));
              let cardClassName = ""
              if (index < 4) {
                cardClassName = "1"
              } else if (found1) {
                cardClassName = "3"
              } else {
                cardClassName = "2"
              }

              if (card[category].compare) {
                // Fatigue Score tile colour.
                if (card[category].compare === "#D7D7D7") {
                  compare = "#D7D7D7";
                } else if (card[category].compare === "OUTLIER_DETECTED" || card[category].compare === "OUTSIDE_LOW" || card[category].compare === "OUTSIDE_HIGH") {
                  compare = "#FD8686";
                } else if (
                  card[category].compare === "UPPER_QRT" ||
                  card[category].compare === "LOWER_QRT"
                ) {
                  compare = "#FFD07B";
                } else if (card[category].compare === "IN_RANGE") {
                  compare = "#50DDB6";
                } else if (card[category].compare === "NO_BASELINE_DATA") {
                  compare = "#DDA0DD";
                } 
              }

              //Decide on the Icon to use for each tile and it's color.
              // Choose unit for the tile.
              if (name === "Distance") {
                renderImage = (
                  <LocalShippingIcon
                    fontSize="medium"
                    style={
                      compare !== "#ffffff"
                        ? { color: "#ffffff" }
                        : { color: grey[400] }
                    }
                  />
                );
                unit = "km";
                value = "4";
              } else if (name === "Duration" || name === "Next Break Time") {
                renderImage = (
                  <Alarm
                    fontSize="medium"
                    style={
                      compare !== "#ffffff"
                        ? { color: "#ffffff" }
                        : { color: grey[400] }
                    }
                  />
                );
                unit = "min";
                value = "4";
              } else if (name === "Fatigue Score") {
                renderImage = (
                  <HelpOutlineIcon
                    fontSize="medium"
                    style={
                      compare !== "#ffffff"
                        ? { color: "#ffffff" }
                        : { color: grey[400] }
                    }
                  />
                );
                unit = "";
                value = "3";
              } else {
                renderImage = (
                  <FavoriteIcon
                    fontSize="medium"
                    style={
                      compare !== "#ffffff"
                        ? { color: "#ffffff" }
                        : { color: grey[400] }
                    }
                  />
                );
                if (name === "Heart Rate Variability") {
                  unit = "%";
                  value = "3";
                } 
              }
              return (
                <div
                  key={category}
                  className={"summarycard-container-mobile" + cardClassName}
                  style={compare ? { backgroundColor: compare } : {}}
                  onClick={(event, newValue) => {
                    props.onSelectDetail(value);
                  }}
                >
                  <div wrap="no wrap" className="summary-card-top-mobile">
                    <span className="summary-card-name-mobile">{name}</span>
                    <div className = "summary-card-imgunit-mobile">
                      <span className="summary-card-image-mobile"> {renderImage} </span>
                      <span className="summary-card-unit-mobile"
                      style={compare !== "#ffffff" ? { color: "#FFFFFF" } : {}}>{unit}</span>
                    </div>
                  </div>
                  <div className={"summary-card-value-mobile"+ cardClassName}>
                    {card[category].value}
                  </div>
                </div>
              );
            })}
          </div>
        </Grid>
      </div>
    </div>
  );
}
