import "./Login.css";
import {Button, Container, Form, Row, Col} from "react-bootstrap";
import truckImage from '../../../public/image/truckImage.png';
import NewPasswordForm from '../Login/NewPasswordForm';
import { useState } from "react";
import { Auth } from "aws-amplify";


export default function Login () {
    let [username, setUsername] = useState('');
    let [password, setPassword] = useState('');
    const [openPopup, setOpenPopup] = useState(false);

    
    // send a request to Auth to log in 
    let login = async function (event) {
        try{
        event.preventDefault();
        let response = await Auth.signIn(username, password)
        //console.log('auth response', response);
        if(response.challengeName === 'NEW_PASSWORD_REQUIRED'){
            setOpenPopup(true);    
        }}
        catch(err){
            alert(err.message);
        }
    };

    return(
        // login form
        <>
            <NewPasswordForm 
            openPopup = {openPopup}
            setOpenPopup = {setOpenPopup}
            username = {username}
            password = {password}
            ></NewPasswordForm>

          <div className="form-wrapper">
            <Container fluid>
            <Row noGutters>
                <Col>
                    <img id="login-img" src={truckImage} alt="truck"></img>
                </Col>
                <Col md={6} xl={6} className="py-5">
                    <div className="py-5 d-flex align-items-center">
                        <div className="container">
                            <Row noGutters>
                                <Col lg={10} xl={7} className="mx-auto">
                                    <h3 className="display-4">Sign In</h3>
                                    <p className="text-muted mb-4">Track your fatigue while driving</p>
                                    <Form onSubmit={login}>
                                        <div className="form-group mb-3">
                                        <Form.Group controlId="formBasicEmail">
                                        <Form.Control className ="form-control rounded-pill border-0 shadow-sm px-4" type="text" placeholder="UserName" value={username} onChange={(e) => setUsername(e.target.value)} />
                                        </Form.Group>
                                        </div>

                                        <div className="form-group mb-3">
                                        <Form.Group controlId="formBasicPassword">
                                        <Form.Control className ="form-control rounded-pill border-0 shadow-sm px-4" type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                        </Form.Group>
                                        </div>

                                        <div className="custom-control custom-checkbox mb-3">
                                            <input id="customCheck1" type="checkbox" className="custom-control-input" />
                                            <label htmlFor="customCheck1" className="custom-control-label">Remember password</label>
                                        </div>
                                        <Button type="submit" className="btn btn-primary btn-block text-uppercase mb-2 rounded-pill shadow-sm">Sign in</Button>
                                        <div className="text-center d-flex justify-content-between mt-4"><p><a href="#" className="font-italic text-muted"> 
                                                <u>Forgot your password?</u></a></p></div>
                                    </Form>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
      </div>
        </>
    );
}


