import { Hub, Auth } from "aws-amplify";
import AWS from "aws-sdk";
import {
  USER_SIGNED_IN,
  GET_PERMISSION,
  FETCH_ORG,
  FETCH_DRIVER,
  FETCH_SESSION,
  FETCH_DATE,
  FETCH_FCAM,
  FETCH_ECG,
  FETCH_MAP,
  FETCH_TIME,
  FETCH_IMAGES,
  FETCH_ONE_ECG,
  LOGIN_SUCCESS,
  SET_USER_GROUP,
  FETCH_FAIL,
  FETCH_SUCCESS,
  FETCH_REQUEST,
  FETCH_PROFILE,
  SET_ORG_TOKEN,
  SET_DRIVER_TOKEN,
} from "../constants/authConstants";
import { SET_ORG_NAME, SET_DRIVER} from "../constants/sessionConstants";
import { timeParse } from "d3-time-format";
import { maxParallelImageRequests } from "mapbox-gl";

export const userSignedIn = () => async (dispatch) => {
  var orgToken, driverToken, profileToken;
  return await Auth.currentAuthenticatedUser()
    .then(
      (userData) => {
        const group =  userData.signInUserSession.accessToken.payload["cognito:groups"];

        orgToken = userData.attributes["custom:organisation"];
        driverToken = userData.attributes["custom:device"];
        console.log(orgToken, typeof(orgToken));
        dispatch({ type: USER_SIGNED_IN, payload: true });
        dispatch(fetchProfileImage( userData.username));
        // dispatch({type: SET_ORG_TOKEN, payload: orgToken});
        // dispatch({type: SET_ORG_NAME, payload: orgToken});
        // dispatch({type: SET_DRIVER_TOKEN, payload: driverToken});
        // dispatch({type: SET_DRIVER, payload: driverToken});
        dispatch({
          type: SET_USER_GROUP,
          payload:
            userData.signInUserSession.accessToken.payload["cognito:groups"],
        })

        if(group === 'KJR'){

        }else if(group === 'org-admin'){
          dispatch({type: SET_ORG_TOKEN, payload: orgToken});
          dispatch({type: SET_ORG_NAME, payload: orgToken});
        }else{
          dispatch({type: SET_ORG_TOKEN, payload: orgToken});
          dispatch({type: SET_ORG_NAME, payload: orgToken});
          dispatch({type: SET_DRIVER_TOKEN, payload: driverToken});
          dispatch({type: SET_DRIVER, payload: driverToken});
        }
      }
    )
    .catch(() => {
      dispatch({ type: USER_SIGNED_IN, payload: false });
    });
};

export const getUserPermission = () => {
  return null;
};

export const fetchOrg = () => async (dispatch) => {
  let cred = await Auth.currentUserCredentials();

  AWS.config.update({
    region: "ap-southeast-2",
    accessKeyId: cred.accessKeyId,
    secretAccessKey: cred.secretAccessKey,
    sessionToken: cred.sessionToken,
  });

  let docClient = new AWS.DynamoDB.DocumentClient();

  let params = {
    TableName: "fm8_organisation_list",
  };

  let orgList = await docClient.scan(params).promise();
  dispatch({ type: FETCH_ORG, payload: orgList.Items });
};

export const fetchOrgFiltered = (keyword) => async (dispatch) => {
  let cred = await Auth.currentUserCredentials();

  AWS.config.update({
    region: "ap-southeast-2",
    accessKeyId: cred.accessKeyId,
    secretAccessKey: cred.secretAccessKey,
    sessionToken: cred.sessionToken,
  });

  let docClient = new AWS.DynamoDB.DocumentClient();
  let params = {
    TableName: "fm8_organisation_list",
    FilterExpression: "contains(#o_name, :o_name)",
    ExpressionAttributeNames: {
      "#o_name" : "o_name",
    },
    ExpressionAttributeValues: {
        ":o_name": keyword,
    }       
  };
  let orgList = await docClient.scan(params).promise();
  dispatch({ type: FETCH_ORG, payload: orgList.Items });
};

export const fetchDriver = (orgname) => async (dispatch) => {
  let cred = await Auth.currentUserCredentials();
  AWS.config.update({
    region: "ap-southeast-2",
    accessKeyId: cred.accessKeyId,
    secretAccessKey: cred.secretAccessKey,
    sessionToken: cred.sessionToken,
  });

  let docClient = new AWS.DynamoDB.DocumentClient();

  let params = {
    TableName: orgname + "_drivers",
  };

  let driverList = await docClient.scan(params).promise();
  dispatch({ type: FETCH_DRIVER, payload: driverList.Items });
};

export const fetchDriverFiltered = (orgname, keyword) => async (dispatch) => {
  let cred = await Auth.currentUserCredentials();
  AWS.config.update({
    region: "ap-southeast-2",
    accessKeyId: cred.accessKeyId,
    secretAccessKey: cred.secretAccessKey,
    sessionToken: cred.sessionToken,
  });

  let docClient = new AWS.DynamoDB.DocumentClient();

  let params = {
    TableName: orgname + "_drivers",
    FilterExpression: "contains(#device_id, :device_id)",
    ExpressionAttributeNames: {
      "#device_id" : "device_id",
    },
    ExpressionAttributeValues: {
        ":device_id": keyword,
    }       
  };

  let driverList = await docClient.scan(params).promise();
  dispatch({ type: FETCH_DRIVER, payload: driverList.Items });
};

export const fetchSession = (orgname, drivername) => async (dispatch) => {
  let cred = await Auth.currentUserCredentials();
  AWS.config.update({
    region: "ap-southeast-2",
    accessKeyId: cred.accessKeyId,
    secretAccessKey: cred.secretAccessKey,
    sessionToken: cred.sessionToken,
  });

  dispatch({ type: FETCH_REQUEST });
  try {
    let docClient = new AWS.DynamoDB.DocumentClient();
    let params = {
      TableName: orgname + "_" + drivername + "_summary",
      KeyConditionExpression: "#device_id = :devid",
      ExpressionAttributeNames: {
        "#device_id" : "device_id",
      },
      ExpressionAttributeValues: {
          ":devid": drivername,
      },
      Limit: 15,
      ScanIndexForward: false,
    };

    let sessionslist = await docClient.query(params).promise();

    let templist = sessionslist.Items;
    // templist = templist.sort((a, b) => sortsessions(a, b));
    console.log("fetch success");
    dispatch({ type: FETCH_SESSION, payload: templist });
    dispatch({ type: FETCH_SUCCESS });
    return (sessionslist.LastEvaluatedKey)
  } catch {
    dispatch({ type: FETCH_FAIL });
  }
  return(undefined)
};

export const fetchSessionMore = (orgname, drivername, sessionDB) => async (dispatch) => {
  let cred = await Auth.currentUserCredentials();
  AWS.config.update({
    region: "ap-southeast-2",
    accessKeyId: cred.accessKeyId,
    secretAccessKey: cred.secretAccessKey,
    sessionToken: cred.sessionToken,
  });

  // dispatch({ type: FETCH_REQUEST });
  try {
    let docClient = new AWS.DynamoDB.DocumentClient();
    let params = {
      TableName: orgname + "_" + drivername + "_summary",
      KeyConditionExpression: "#device_id = :devid",
      ExpressionAttributeNames: {
        "#device_id" : "device_id",
      },
      ExpressionAttributeValues: {
          ":devid": drivername,
      },
      Limit: 14,
      ScanIndexForward: false,
    };

    params.ExclusiveStartKey = {session: ""+sessionDB[sessionDB.length-1].session, device_id: drivername};

    let sessionslist = await docClient.query(params).promise();

    let templist = sessionslist.Items;
    // templist = templist.sort((a, b) => sortsessions(a, b));
    console.log("fetch success");
    dispatch({ type: FETCH_SESSION, payload: [...sessionDB, ...templist] });
    dispatch({ type: FETCH_SUCCESS });
    return (sessionslist.LastEvaluatedKey)
  } catch {
    dispatch({ type: FETCH_FAIL });
  }
  return (undefined)
};

export const fetchSessionFilteredMore = (orgname, drivername, keyword, sessionDB) => async (dispatch) => {
  let cred = await Auth.currentUserCredentials();
  AWS.config.update({
    region: "ap-southeast-2",
    accessKeyId: cred.accessKeyId,
    secretAccessKey: cred.secretAccessKey,
    sessionToken: cred.sessionToken,
  });

  // dispatch({ type: FETCH_REQUEST });
  try {
    let docClient = new AWS.DynamoDB.DocumentClient();
    let params = {
      TableName: orgname + "_" + drivername + "_summary",
      KeyConditionExpression: "#device_id = :devid and begins_with (#session, :session)",
      ExpressionAttributeNames: {
        "#session" : "session",
        "#device_id" : "device_id",
      },
      ExpressionAttributeValues: {
          ":session": keyword,
          ":devid": drivername,
      },
      Limit: 14,
      ScanIndexForward: false,
    };
    params.ExclusiveStartKey = {session: ""+sessionDB[sessionDB.length-1].session, device_id: drivername};
    let sessionslist = await docClient.query(params).promise();

    let templist = sessionslist.Items;
    // templist = templist.sort((a, b) => sortsessions(a, b));
    console.log("fetch success");
    dispatch({ type: FETCH_SESSION, payload: [...sessionDB, ...templist] });
    dispatch({ type: FETCH_SUCCESS });
    return (sessionslist.LastEvaluatedKey)
  } catch {
    dispatch({ type: FETCH_FAIL });
  }
  return (undefined)
};

export const fetchSessionFiltered = (orgname, drivername, keyword) => async (dispatch) => {
  let cred = await Auth.currentUserCredentials();
  AWS.config.update({
    region: "ap-southeast-2",
    accessKeyId: cred.accessKeyId,
    secretAccessKey: cred.secretAccessKey,
    sessionToken: cred.sessionToken,
  });

  dispatch({ type: FETCH_REQUEST });
  try {
    let docClient = new AWS.DynamoDB.DocumentClient();
      let params = {
      TableName: orgname + "_" + drivername + "_summary",
      KeyConditionExpression: "#device_id = :devid and begins_with (#session, :session)",
      ExpressionAttributeNames: {
        "#session" : "session",
        "#device_id" : "device_id",
      },
      ExpressionAttributeValues: {
          ":session": keyword,
          ":devid": drivername,
      },
      Limit: 15,
      ScanIndexForward: false,
    };

    let sessionslist = await docClient.query(params).promise();

    let templist = sessionslist.Items;
    // templist = templist.sort((a, b) => sortsessions(a, b));
    console.log("fetch success");
    dispatch({ type: FETCH_SESSION, payload: templist });
    dispatch({ type: FETCH_SUCCESS });
    return (sessionslist.LastEvaluatedKey)
  } catch {
    dispatch({ type: FETCH_FAIL });
  }
  return (undefined)
};

export const fetchDate = (orgname, drivername, session) => async (dispatch) => {
  let cred = await Auth.currentUserCredentials();
  AWS.config.update({
    region: "ap-southeast-2",
    accessKeyId: cred.accessKeyId,
    secretAccessKey: cred.secretAccessKey,
    sessionToken: cred.sessionToken,
  });

  let docClient = new AWS.DynamoDB.DocumentClient();

  let params = {
    TableName: orgname + "_" + drivername,
    KeyConditionExpression: "#se = :date",
    ExpressionAttributeNames: {
      "#se": "session",
    },
    ExpressionAttributeValues: {
      ":date": session,
    },
  };

  let result = await docClient.query(params).promise();
  let timelist = result.Items;

  const stringToDate = (d) => {
    if (d.json_source === "ECG") {
      let e_et = timeParse("%d-%b-%Y_%H-%M-%S-%f")(d.event_time);
      let e_dtw = timeParse("%d-%b-%Y_%H-%M-%S-%f")(d.device_time_written);
      if (!e_et) {
        e_et = timeParse("%d-%m-%Y_%H-%M-%S-%f")(d.event_time);
      }
      if (!e_et) {
        e_et = timeParse("%Y-%m-%d_%H-%M-%S-%f")(d.event_time);
      }
      if (!e_dtw) {
        e_dtw = timeParse("%d-%m-%Y_%H-%M-%S-%f")(d.device_time_written);
      }
      if (!e_dtw) {
        e_dtw = timeParse("%Y-%m-%d_%H-%M-%S-%f")(d.device_time_written);
      }
      d.event_time = e_et;
      d.device_time_written = e_dtw;
    } else if (d.json_source === "FCAM") {
      let f_et = timeParse("%Y-%m-%d_%H:%M:%S.%f")(
        d.session + "_" + d.event_time
      );
      let f_dtw = timeParse("%d-%b-%Y_%H-%M-%S-%f")(d.device_time_written);
      if (!f_et) {
        f_et = timeParse("%Y-%m-%d_%H-%M-%S-%f")(
          d.session + "_" + d.event_time
        );
      }
      if (!f_dtw) {
        f_dtw = timeParse("%d-%m-%Y_%H-%M-%S-%f")(d.device_time_written);
      }      
      if (!f_dtw) {
        f_dtw = timeParse("%Y-%m-%d_%H-%M-%S-%f")(d.device_time_written);
      }

      d.event_time = f_et;
      d.device_time_written = f_dtw;
    } else {
      //do nothing
    }
  };

  timelist.forEach((element) => {
    stringToDate(element);
  });

  //10-Jan-2021_21-16-43-121409
  //
  //22:01:49.999993

  timelist = timelist.sort((a, b) => a.event_time - b.event_time);

  const fcamList = timelist.filter((d) => d.json_source === "FCAM");
  const ecgList = timelist.filter((d) => d.json_source === "ECG");
  const mapList = timelist.filter((d) => d.gps_raw !== null);
  const ECGtime = new Date(fcamList[0].event_time);
  //console.log("fetchDate Finished!");
  dispatch({ type: FETCH_DATE, payload: timelist });
  dispatch({ type: FETCH_FCAM, payload: fcamList });
  dispatch({ type: FETCH_ECG, payload: ecgList });
  dispatch({ type: FETCH_MAP, payload: mapList });
  //this sets the time to the 1st image's time for ECG reasons. otherwise it default to time.now() causing oldest date to appear in ECG on 1st load.
  dispatch({ type: FETCH_TIME, payload: ECGtime });
};

export function sortsessions(a, b) {
  return new Date(b.session) - new Date(a.session);
}

export const fetchImage = (keyList) => async (dispatch) => {
  let cred = await Auth.currentUserCredentials();
  AWS.config.update({
    region: "ap-southeast-2",
    accessKeyId: cred.accessKeyId,
    secretAccessKey: cred.secretAccessKey,
    sessionToken: cred.sessionToken,
  });

  let s3 = new AWS.S3();

  let imgList = [];

  for (let i = 0; i < keyList.length; i++) {
    let params = {
      Bucket: "fatiguem8-data-store",
      Key: keyList[i],
    };
    try{
      let imgByte = await s3.getObject(params).promise();
      //console.log(imgByte)
      imgList.push(imgByte.Body);
    } catch (error) {
      console.log(error)
    }
    if (i > 20) {
      break;
    }
  }

  dispatch({ type: FETCH_IMAGES, payload: imgList });
};

export const fetchOneEcgData =
  (device, session, filename) => async (dispatch) => {
    let cred = await Auth.currentUserCredentials();
    AWS.config.update({
      region: "ap-southeast-2",
      accessKeyId: cred.accessKeyId,
      secretAccessKey: cred.secretAccessKey,
      sessionToken: cred.sessionToken,
    });

    let s3 = new AWS.S3();
    // let params = {
    //   Bucket: "fatiguem8-data-store",
    //   Key: device + "/" + session + "/" + filename,
    // };

    // let ecgByte = await s3.getObject(params).promise();
    // let result = ecgByte.Body.toString("ascii")
    //   .split(",")
    //   .map((d, i) => {
    //     return { x: i, y: parseInt(d) };
    //   });
    let result = "";
    for (let i = 0; i < filename.length; i++) {
      let params = {
        Bucket: "fatiguem8-data-store",
        Key: device + "/" + session + "/" + filename[i],
      };
      try{
        let ecgByte = await s3.getObject(params).promise();
        result += ecgByte.Body.toString("ascii") + ","; //adding a , because we are joining multiple files
        //console.log(result)
      } catch (error) {
        console.log(error)
      }
    }
    result = result.slice(0, -1); //remove last , from string we added
    result = result.split(",")
                    .map((d, i) => {
                      return {x: i * (3.33/1000), y: parseInt(d) } //3.33 is roughly 300HZ -> milliseconds. /1000 to turn into seconds
                    })
    //console.log(result);
    dispatch({ type: FETCH_ONE_ECG, payload: result });
  };

  export const fetchProfileImage = (username) => async (dispatch) => {
    let cred = await Auth.currentUserCredentials();
    AWS.config.update({
      region: "ap-southeast-2",
      accessKeyId: cred.accessKeyId,
      secretAccessKey: cred.secretAccessKey,
      sessionToken: cred.sessionToken,
    });
  
    let s3 = new AWS.S3();

    let paramsList = {
        Bucket: "fatiguem8-profile-photo",
        Delimiter: '/',
        Prefix: username + '/profile'
    };

    const data = await s3.listObjects(paramsList).promise();

    if (data['Contents'].length > 0) {
      let profiles3url = data['Contents'][0]['Key']
      let params = {
        Bucket: "fatiguem8-profile-photo",
        Key: profiles3url,
      };
  
      // let profileS3= await s3.getSignedUrlPromise('getObject', params); //To get timed presignedlink use commented out part but add timer
      // console.log(profileS3)
      let profileS3= await s3.getObject(params).promise();
      // const base64String = btoa(String.fromCharCode(...new Uint8Array(profileS3.Body)));
      // console.log(base64String)
    
      // dispatch({ type: FETCH_PROFILE, payload: profileS3 });
      dispatch({ type: FETCH_PROFILE, payload: profileS3.Body });
    }
  };