import { React, useEffect } from "react";
import { Route, Switch } from "react-router";
import "./App.css";
import Nav from "./components/Nav/Nav";
import SelectOrg from "./components/SelectOrg";
import SelectDriver from "./components/SelectDriver";
import SelectSession from "./components/SelectSession";
import LoginScreen from "./screens/LoginScreen";
import BreadCrumbs from "./components/BreadCrumbs";
import Home from "./components/Home";
import { BrowserRouter as Router, HashRouter } from "react-router-dom";
import { userSignedIn } from "./actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import ProfileScreen from "./screens/ProfileScreen";
import 'mapbox-gl/dist/mapbox-gl.css';

function App() {
  const dispatch = useDispatch();
  const signedIn = useSelector((state) => state.authInfo.signedIn);

  useEffect(() => {
    dispatch(userSignedIn());
    //document.title = "FatigueM8";
  }, []);

  return (
    <>
      <HashRouter>
        <Nav />
        {signedIn ? <BreadCrumbs /> : null}
        <Switch>
          <Route exact path="/" render={(props) => <Home {...props} />} />
          <Route exact path="/login" component={LoginScreen} />
          <Route exact path="/profile" component={ProfileScreen} />
          <Route
            exact
            path="/:selectorg"
            render={(props) => <SelectOrg {...props} />}
          />
          <Route
            exact
            path="/:orgname/:selectdriver"
            render={(props) => <SelectDriver {...props} />}
          />
          <Route
            exact
            path="/:orgname/:drivername/:currentsession"
            render={(props) => <SelectSession {...props} />}
          />
        </Switch>
      </HashRouter>
    </>
  );
}

export default App;
