import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import * as d3 from "d3";
import mapboxgl from "mapbox-gl";
import "./MapTracker.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker";
mapboxgl.workerClass = MapboxWorker;
mapboxgl.accessToken =
  "pk.eyJ1IjoidG9tcm9oMTk5MyIsImEiOiJja3Jtejk3Nms1OXZkMnJyMnp5MGpheGoyIn0.v8Q4_Jmilxk0dn8Qx5bm6Q";

const MapTracker = () => {
  const mapData = useSelector((state) => state.authInfo.mapDB);
  const [defaultMap, setDefaultMap] = useState({
    lng: 133.833042,
    lat: -24.96614,
    zoom: 2.5,
  });
  const map = useRef(null);
  const mapContainer = useRef();

  useEffect(() => {
    if (map.current) return;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [defaultMap.lng, defaultMap.lat],
      zoom: defaultMap.zoom,
    });
  }, []);

  useEffect(() => {
    if (!map.current || mapData.length === 0) {
      return;
    } else {
      const drawMap = (data) => {
        const xmin = d3.min(data.map((d) => d.gps_raw.lng));
        const xmax = d3.max(data.map((d) => d.gps_raw.lng));
        const ymin = d3.min(data.map((d) => d.gps_raw.lat));
        const ymax = d3.max(data.map((d) => d.gps_raw.lat));

        map.current.on("viewreset", renderPoint);
        map.current.on("move", renderPoint);
        map.current.on("moveend", renderPoint);
        map.current.fitBounds(
          [
            [xmin, ymin],
            [xmax, ymax],
          ],
          { padding: 20 }
        );
      };

      const drawScatter = (data) => {
        d3.selectAll(".mapboxgl-canvas")
          .style("opacity", 1)
          .style("position", "absolute")
          .style("width", "100%")
          .style("height","100%");
        // .style("z-index", 1);

        let container = map.current.getCanvasContainer();
        d3.select(container).selectAll("svg").remove(); //used to remove the current scatter plots before appending a new one

        let svg = d3
          .select(container)
          .append("svg")
          .attr("id", "mapScatter")
          .attr("fill", "none")
          .style("position", "absolute")
          .style("width", "100%")
          .style("height","100%");
        // .style("z-index", 10);

        const circles = svg
          .selectAll("circle")
          .data(data)
          .join("circle")
          .attr("class", "mapTracker")
          .attr("r", 5);
      };

      const project = (d) => {
        return map.current.project(new mapboxgl.LngLat(d.lng, d.lat));
      };

      const renderPoint = () => {
        const tooltip = document.getElementById("tooltip");
      
        d3.selectAll(".mapTracker")
        //d in these 2 below cases is the marker itself with the attached data
          .attr("cx", (d) => project(d.gps_raw).x)
          .attr("cy", (d) => project(d.gps_raw).y)
          .on("mouseover", function (event) {
            // Show the tooltip on mouseover
            //event refers to the mouseover event. inside contains lots of info like the element is calling the event.
            //console.log(event.target.__data__);
            console.log(event);
            tooltip.style.display = "block";
            const [x, y] = d3.pointer(event); // Use d3.pointer to get x, y coordinates
            tooltip.style.left = event.x + "px";
            tooltip.style.top = event.y + "px";
            tooltip.innerHTML = "Data";
            try{
              tooltip.innerHTML += "<br>Event Time: " + event.target.__data__.event_time;
              tooltip.innerHTML += "<br>Lng: " + event.target.__data__.gps_raw.lng + "&nbsp; Lat: " + event.target.__data__.gps_raw.lat;
              tooltip.innerHTML += "<br>Speed: " + event.target.__data__.speed + "&nbsp; Hands: " + event.target.__data__.payload.ecg_analysis[0].hands;
              tooltip.innerHTML += "<br>RMSSD: " + event.target.__data__.payload.ecg_analysis[0].RMSSD + "&nbsp; SDSD: " + event.target.__data__.payload.ecg_analysis[0].SDSD;
              tooltip.innerHTML += "<br>Heart Rate: " + event.target.__data__.payload.ecg_analysis[0].heart_rate + "&nbsp;"
            }
            catch(error){
              //console.log(error);
              tooltip.innerHTML += "<br> Error Loading Data for this point.";
            }
            
          })
          .on("mouseout", () => {
            // Hide the tooltip on mouseout
            tooltip.style.display = "none";
          });
      };
      
      
      

      const animation = (data, ecgFullData) => {
        const ecgData = ecgFullData.filter(
          (d) => d.payload.ecg_analysis[0].heart_rate !== 0
        );
        const sortedHeart = ecgData
          .map((d) => d.payload.ecg_analysis[0].heart_rate)
          .sort(d3.ascending);
        const hquantile1 = d3.quantile(sortedHeart, 0.25);
        const hquantile3 = d3.quantile(sortedHeart, 0.75);
        const hMin = Math.max(
          sortedHeart[0],
          hquantile1 - (hquantile3 - hquantile1) * 1.5
        );
        const hMax = Math.min(
          sortedHeart[sortedHeart.length - 1],
          hquantile3 + (hquantile3 - hquantile1) * 1.5
        );
        const delay = 5000 / data.length;
        const motion = d3
          .selectAll(".mapTracker")
          .transition()
          .attr("fill", "steelblue")
          .attr("r", 3)
          .delay(function (d, i) {
            return i * delay;
          });
        motion
          .filter((d, i) => d.json_source === "FCAM")
          .transition()
          .attr("fill", "gray")
          .attr("r", 0);
        motion
          .filter(
            (d, i) =>
              (d.json_source === "ECG" &&
                d.payload.ecg_analysis[0].heart_rate > hMin &&
                d.payload.ecg_analysis[0].heart_rate < hquantile1) ||
              (d.heart_rate > hquantile3 && d.heart_rate < hMax)
          )
          .transition()
          .attr("fill", "yellow");
        motion
          .filter(
            (d, i) =>
              d.json_source === "ECG" &&
              d.payload.ecg_analysis[0].heart_rate >= hquantile1 &&
              d.payload.ecg_analysis[0].heart_rate <= hquantile3
          )
          .transition()
          .attr("fill", "green");
        motion
          .filter(
            (d, i) =>
              d.json_source === "ECG" &&
              (d.payload.ecg_analysis[0].heart_rate <= hMin ||
                d.heart_rate >= hMax)
          )
          .transition()
          .attr("fill", "red");
        motion
          .filter(
            (d, i) =>
              d.json_source === "ECG" &&
              d.payload.ecg_analysis[0].heart_rate === 0
          )
          .transition()
          .attr("fill", "none")
          .attr("stroke", "black")
          .attr("stroke-width", 1)
          .attr("r", 0);
      };

      drawMap(mapData);
      setTimeout(() => {
        //console.log(mapData);
        drawScatter(mapData);
        renderPoint();
        animation(
          mapData,
          mapData.filter((d) => d.json_source === "ECG")
        );
      }, 800);
    }
  }, [mapData]);

  return <div className="map"><div ref={mapContainer} className="mapContainer"></div><div id="tooltip" className="tooltipClass"></div></div>;
};

export default MapTracker;
